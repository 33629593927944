import { call, put } from "redux-saga/effects";
import Api from "../services/ApiCaller";
import {Alert} from 'react-bootstrap'

import { signInStart,
  signInSuccess,
  signInFailure,
  signUpStart,
  signUpSuccess,
  signUpFailure,
  userLogout,
  resetUserReduxOnMount,
  
  
} from "./userredux";

import { allStateReset } from "./appredux";


export function* userLoginSaga(api, { payload }) {

    yield put(signInStart());
    try {
      const { email, password } = payload;
      const result = yield call(
        Api.callServer,
        api.login,
        { email: email, password: password },
        true
      );
      if(result.role !== "User"){
        alert("Invalid login details")
      yield put(signInFailure("Invalid login details"));
        return
      }
      yield put(signInSuccess({email, ...result}))
      payload.setShowLogin && payload.setShowLogin(false)
      payload.handleClose && payload.handleClose()
      if(payload.setProjectFromShow !==undefined){
        payload.setProjectFromShow(true)
        
      }

      // const token = yield getAccessToken();
      
    } catch (err) {
      yield put(signInFailure(err.message));
      if (err.message.includes("User doest not exist") || (err.message.includes("No User Account is Available"))){

        alert("Account with this email address doesn't exist")
        
      }else if(err.message.includes("Invalid Password")){

        alert("Invalid login credentials. Please double-check your email and password and try again")
      }else{
        alert(err.message)

      }


    }
  }

  export function* logoutSaga(api, { payload }) {
    try {
      yield put(userLogout());
      yield put (resetUserReduxOnMount())
      yield put (allStateReset ())
      yield payload.navigate.navigate('/');
      // if (payload) {
      //   payload.replace("/");
      // }
    } catch (error) {}
  }

  export function* resetLoadingSaga(api, {payload}){
    yield put (resetUserReduxOnMount())
    
  }

  export function* userSignUpSaga(api, { payload }) {
    yield put(signUpStart());

    
    try {
      let data = {sendVerification:true, ...payload}
      const result = yield call(Api.callServer, api.signUpUser, data, true);
      yield put(signUpSuccess(result));
      payload.resetState()
      const result2 = yield call(
        Api.callServer,
        api.login,
        { email: payload.email, password: payload.password },
        true
      );
      let email = payload.email
      yield put(signInSuccess({email, ...result2}))
      if(payload.setProjectFromShow !==undefined){
        payload.setProjectFromShow(true)
      }else{
        payload.showModal(false)
        payload.setShowLogin(false)
      payload.setShow(true)

      }
    } catch (e) {
      yield put(signUpFailure(e.message));
      if(e.message.includes("password must be longer than or equal to 8 characters")){
      alert("Password must be longer than or equal to 8 characters")
      }else if( e.message.includes("EMAIL IS ALREADY USED")){

        alert("The email address you have entered is already in use.")
      }else{
        alert(e.message)
      }
    }
  }
