import React from "react"
import {Modal, Button, Form} from "react-bootstrap"
import {useState} from "react"
import android from "@assets/images/deliveryPage/android.png"
import ios from "@assets/images/deliveryPage/ios.png"
import windows from "@assets/images/deliveryPage/windows.png"
import mobile_phone from "@assets/images/deliveryPage/phone.svg"

import {Link, useNavigate} from "react-router-dom"
import {SuccessPopUp} from "@components/popups/PopUpCards"
import * as _ from "lodash"
import Loader from "@components/commonComponets/Loader/Loader"
import {useEffect} from "react"
import LoginCard from "@components/authComponents/LoginCard"
import SignUpCard from "@components/authComponents/SignUpCard"
import ForgotCard from "@components/authComponents/ForgotCard"
import {
  DeliveryCostFooter,
  DeliveryButton,
} from "@components/costFooter/TemplateCostFooter"
import {connect} from "react-redux"
import DeliveryForm from "@components/deliveryPageComponents/DeliveryForm"
import {useLocation} from "react-router-dom"
import {IconButton} from "@material-ui/core"
import {ArrowBackRounded} from "@material-ui/icons"

function DeliveryCard(props) {
  let deliveryPlans = [
    {
      id: 1,
      type: "Relaxed",
      cost: 5,
      time: "10 Week",
    },
    {
      id: 2,
      type: "Standard",
      cost: 10,
      time: "8 Week",
    },
    {
      id: 3,
      type: "Fast",
      cost: 15,
      time: "6 Week",
    },
  ]

  const {submitProjectLoading, requestedData, auth, signedInUser} = props

  let randNumber = Date.now().toString().substring(6)
  // let str = number.toString();
  // let newStr = str.substring(6);

  const location = useLocation()
  const navigate = useNavigate()
  const data = location.state?.data
  let templateId = requestedData?.templateId

  const [show, setShow] = useState(false)
  const [platfromAddonCost, setPlatformAddonCost] = useState(0)
  const [addOnFeatureCost, setAddOnFeatureCost] = useState(
    data?.addOnFeatureCost
  )

  const [standardCost, setStandardCost] = useState(data?.standardCost)
  const [fixedCost, setFixedCost] = useState("50000")
  const [platform, setPlatform] = useState(["Web"])
  // const [isDeliveryPlanSelect, setDeliveryPlam] = useState();
  const [deliveryTime, setDeliveryTime] = useState()
  const [finalCost, setFinalCost] = useState(0)
  const [selectedOption, setSelectedOption] = useState("")

  const [projectName, setProjectName] = useState(
    `${requestedData.name}${" "}${randNumber}`
  )
  const [description, setDescription] = useState("")
  const [message, setMessage] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState()

  const [projectNameError, setProjectNameError] = useState("")
  const [descriptionError, setDescriptionError] = useState("")
  const [emailError, setEmailError] = useState("")

  const [projectFromShow, setProjectFromShow] = useState(false)
  const [errors, setErrors] = useState({})
  const [planChecked, setPlanChecked] = useState(false)

  const [formData, setFormData] = useState({
    projectName: `${requestedData.name}`,
    description: "",
    email: signedInUser?.email,
    mobile: "",
    message: "",
  })

  // const [deliveryDate, setDeliveryDate] = useState();
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    if (platform.length > 1) {
      let extraCost = parseFloat(addOnFeatureCost) * 0.35
      setPlatformAddonCost(extraCost)
    } else {
      setPlatformAddonCost(0)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platform, isChecked, addOnFeatureCost])

  useEffect(() => {
    if (auth) {
      setEmail(signedInUser?.email)
      setFormData({
        ...formData,
        email: signedInUser?.email,
      })
    }
    // window.location.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  let currentDate = new Date()
  let inputString = deliveryTime ? deliveryTime : "11 weeks"
  let numWeeks = parseInt(inputString)
  let futureDate = new Date(
    currentDate.getTime() + numWeeks * 7 * 24 * 60 * 60 * 1000
  )
  const yyyy = futureDate.getFullYear()
  let mm = futureDate.getMonth() + 1 // Months start at 0!
  let dd = futureDate.getDate()
  if (dd < 10) dd = "0" + dd
  if (mm < 10) mm = "0" + mm
  const formattedDate = dd + "/" + mm + "/" + yyyy

  // setDeliveryDate(formattedDate)

  const handleClose = () => {
    setShow(false)
    navigate("/")
    // const handleShow = () => setShow(true);
  }

  const handleChange = (event, item) => {
    if (event.target.checked) {
      setPlatform([...platform, item])
    } else {
      var index = platform.indexOf(item)
      if (index > -1) {
        platform.splice(index, 1)
        setPlatformAddonCost(0)
      }
    }
    setIsChecked(item)
  }

  const handleFieldChange = event => {
    const {name, value} = event.target
    setFormData({
      ...formData,
      [name]: value,
    })

    // Remove error when typing in a field
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      })
    }
  }

  const optionChangeHandler = (event, item) => {
    setPlanChecked(false)
    let totalProjectCost =
      parseInt(data?.fixedCost) +
      parseInt(data?.standardCost) +
      parseInt(data?.addOnFeatureCost ? data?.addOnFeatureCost : 0)
    let finalCost = (totalProjectCost * item.cost) / 100 + totalProjectCost
    setFinalCost(finalCost)
    setDeliveryTime(item.time)
    setSelectedOption(event.target.value)
    // if(event.target.value === "Relaxed"){
    //   setDeliveryTime("21 Weeks")
    // }else if(event.target.value === "Standard"){
    //   setDeliveryTime("18 Weeks")
    // }else if (event.target.value === "Fast"){
    //   setDeliveryTime("15 Weeks")
    // }
    // setSelectedOption(!selectedOption);

    //     const today = new Date();
    // const twoWeeksLater = new Date(today.getTime() + (14 * 24 * 60 * 60 * 1000));

    // console.log('Today:', today.toDateString());
    // console.log('Two weeks later:', twoWeeksLater.toDateString());
  }
  const handleCloseProjectFrom = () => {
    setProjectFromShow(false)
  }

  const projectFromHandler = () => {
    if (selectedOption && platform.length > 0) {
      setProjectFromShow(true)
    } else {
      setPlanChecked(true)
      alert("Please select the platform and delivery type")
    }
  }

  const validateForm = () => {
    const newErrors = {}

    if (formData.projectName.trim() === "") {
      newErrors.name = "Name is required"
    }

    if (formData.email.trim() === "") {
      newErrors.email = "Email is required"
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address"
    }
    const mobileNumberRegex = /^[1-9][0-9]{9}$/
    const isValidMobileNumber = mobileNumberRegex.test(formData.mobile)
    if (!isValidMobileNumber) {
      newErrors.mobile = "Please enter the valid mobile number"
    }

    if (formData.description.trim() === "") {
      newErrors.description = "Descriptio is required"
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const submitQuaryHandler = e => {
    let customFeatures = []
    if (requestedData.customFeatures) {
      customFeatures = requestedData.customFeatures?.map(obj => {
        const newObj = {...obj}
        // Remove the "id" from the copied object
        delete newObj.id
        return newObj
      })
    }
    e.preventDefault()
    if (validateForm()) {
      let requestData = {
        projectName: `${formData.projectName}${" "}${randNumber}`,
        description: formData.description,
        email: formData.email,
        phoneNo: parseInt(formData.mobile),
        message: formData.message,
        platform: platform,
        deliveryDate: "4 weeks",
        templateId: parseInt(requestedData.templateId.id),
        featureIds: requestedData.featureIds,
        totalFeatureCost: 0,
        deliveryCost: requestedData.standardCost,
        addOnFeatureCost: requestedData?.addOnFeatureCost,
        totalProjectCost: finalCost,
        customFeatures: customFeatures,
        // parseInt(requestedData.fixedCost) +
        // parseInt(requestedData.standardCost) +
        // parseInt(
        //   requestedData?.addOnFeatureCost
        //     ? requestedData?.addOnFeatureCost
        //     : 0
        // ),
        setShow,
        resetState,
        setForm: setProjectFromShow,
      }
      console.log(requestData)
      props.addPorjcect(requestData)
    }
  }

  const resetState = () => {
    setProjectName("")
    setEmail("")
    setMobile()
    setDescription("")
    setMessage("")
    setFormData({
      name: "",
      email: "",
      message: "",
    })
    setErrors({})
  }

  const [forgotShow, setShowForgot] = useState(false)
  const handleForgotClose = () => setShowForgot(false)
  const [loginShow, setShowLogin] = useState(false)
  const [signUpShow, setShowSignUp] = useState(false)
  const [loginCard, setLoginCard] = useState(false)
  const [signUpCard, setSignUpCard] = useState(false)
  const [forgetCard, setForgetCard] = useState(false)

  const handleLoginClose = () => setShowLogin(false)

  const handleSignUpShow = () => {
    setShowSignUp(true)
    setLoginCard(false)
    setSignUpCard(true)
  }

  const handleForgotShow = () => {
    setLoginCard(false)
    setForgetCard(true)
    setSignUpCard(false)
  }

  const handleLoginShow = () => {
    setShowLogin(true)
    setShowSignUp(false)
    setShowForgot(false)
    setLoginCard(true)
  }

  return (
    <>
      {/* {submitProjectLoading && <Loader />} */}
      {/* // -----------------------Success MEssage Modal--------------------------- */}
      <SuccessPopUp
        message={
          "Your request has been submitted successfully. Our team will review your request and get back to you as soon as possible."
        }
        show={show}
        status={"succes"}
        button_text={"Close"}
        handleClose={handleClose}
      />
      {/* <Modal
        className="submit-success-popup"
        
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <SuccessPopUp
          
          button_text={"Close"}
          cancel={handleClose}
          status = {"success"}
        />
      </Modal> */}
      <DeliveryForm
        handleFieldChange={handleFieldChange}
        projectName={projectName}
        setProjectName={setProjectName}
        show={projectFromShow}
        description={description}
        setDescription={setDescription}
        message={message}
        setMessage={setMessage}
        mobile={mobile}
        setMobile={setMobile}
        email={email}
        setEmail={setEmail}
        closeForm={setProjectFromShow}
        onHide={handleCloseProjectFrom}
        emailError={emailError}
        projectNameError={projectNameError}
        descriptionError={descriptionError}
        setDescriptionError={setDescriptionError}
        setProjectNameError={setProjectNameError}
        setEmailError={setEmailError}
        submitHandler={submitQuaryHandler}
        submitProjectLoading={submitProjectLoading}
        formData={formData}
        errors={errors}
      />

      <Modal
        className="auth-modal"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        show={loginShow}
        onHide={handleLoginClose}
      >
        {/* <Modal.Body> */}

        {loginCard ? (
          <LoginCard
            handleClose={handleLoginClose}
            setShowLogin={setShowLogin}
            showModal={handleSignUpShow}
            showForgot={handleForgotShow}
            setProjectFromShow={setProjectFromShow}
          />
        ) : signUpCard ? (
          <SignUpCard
            handleClose={handleLoginClose}
            showModal={handleLoginShow}
            setProjectFromShow={setProjectFromShow}
          />
        ) : forgetCard ? (
          <ForgotCard
            handleClose={handleLoginClose}
            showModal={handleLoginShow}
          />
        ) : null}

        {/* </Modal.Body> */}
      </Modal>

      <div className="container-fluid delivery-main-section">
        <div className="sub-section">
          <div className="top-section-heading">
            <div className="">
              <Link
                to={`/details/${templateId?.id}`}
                // onClick={()=> `/details/${templateId?.id}`}
              >
                <IconButton
                  aria-label="delete"
                  disabled
                  color="primary"
                  size="large"
                >
                  <ArrowBackRounded fontSize="large" />
                </IconButton>
                {/* <i class="fa fa-angle-left" aria-hidden="true"></i> */}
              </Link>
            </div>

            <div className="delivery-title">
              <h3>Decide your deliverables</h3>
            </div>
          </div>

          <p className="sub-title">Select platform for your product</p>

          <div className="platform-row">
            <div className="mobile">
              <div className="platform-select-box">
                <div className="os-logo d-flex justify-content-center">
                  <img
                    alt="android logo"
                    src={mobile_phone}
                    title="android-logo"
                    style={{width: "40%"}}
                  />
                </div>

                <div className="custom-checkbox">
                  <Form.Check
                    type="checkbox"
                    // checked={true}
                    onChange={e => handleChange(e, "Mobile")}
                    custom
                  ></Form.Check>
                </div>
              </div>
              <div className="platform-title">Mobile APP</div>
              <div className="platform-title">(IOS & Android)</div>
            </div>

            {/* <div className="platform-select-box">
              <div className="os-logo d-flex justify-content-center" >
                <img alt="ios logo" src={ios} title="android-logo" />
              </div>

              <div className="custom-checkbox">
                <Form.Check
                  type="checkbox"
                  // checked={isChecked}
                  onChange={(e) => handleChange(e, "IOS")}
                  custom
                ></Form.Check>
              </div>
            </div> */}
            <div className="mobile">
              <div className="platform-select-box">
                <div className="os-logo d-flex justify-content-center">
                  <img alt="web logo" src={windows} title="android-logo" />
                </div>

                <div className="custom-checkbox">
                  <Form.Check
                    type="checkbox"
                    defaultChecked={true}
                    onChange={e => handleChange(e, "Web")}
                    custom
                  ></Form.Check>
                </div>
              </div>

              <div className="platform-title">Web APP</div>
              <div className="platform-title text-white">{".     ."}</div>
            </div>
          </div>
        </div>

        <div className="delivery-time-section">
          <h3>When do you want the delivery ?</h3>

          <div className="delivery-nature">
            <Form.Group className="delivery-plans-group d-lg-flex d-md-flex">
              {/* <div className="plan-box">
                <div className="plan-containt">
                  <p>Relaxed</p>
                  <p>₹ 15,91,000.00</p>
                  <p>21 Weeks</p>
                </div>

                <Form.Check
                  inline
                  type="checkbox"
                  value="Relaxed"
                  checked={selectedOption === "Relaxed"}
                  onChange={optionChangeHandler}
            
                />
              </div> */}

              {/* <div className="plan-box">
                <div className="plan-containt">
                  <p>Slow</p>
                  <p>₹ 17,00,059.00</p>
                  <p>19 Weeks</p>
                </div>

                <Form.Check
                  inline
                  type="checkbox"
                  value="option2"
                  checked={selectedOption === "option2"}
                  onChange={optionChangeHandler}
                />
              </div> */}
              {deliveryPlans?.map((item, index) => (
                <div
                  key={index}
                  className={`plan-box ${planChecked && "checkbox-alert"}`}
                >
                  <div className="plan-containt">
                    <p>{item.type}</p>
                    <p>{`${item.cost} %`}</p>
                    <p>{item.time}</p>
                  </div>
                  <Form.Check
                    inline
                    type="checkbox"
                    value={item.type}
                    checked={selectedOption === item.type}
                    onChange={e => optionChangeHandler(e, item)}
                  />
                </div>
              ))}

              {/* <div className="plan-box">
                <div className="plan-containt">
                  <p>Fast</p>
                  <p>₹ 25,00,059.00</p>
                  <p>16 Weeks</p>
                </div>

                <Form.Check
                  inline
                  type="checkbox"
                  value="Fast"
                  checked={selectedOption === "Fast"}
                  onChange={optionChangeHandler}
                />
              </div> */}
              {/* 
              <div className="plan-box">
                <div className="plan-containt">
                  <p>Speedy</p>
                  <p>₹ 28,00,059.00</p>
                  <p>14 Weeks</p>
                </div>

                <Form.Check
                  inline
                  type="checkbox"
                  value="option5"
                  checked={selectedOption === "option5"}
                  onChange={optionChangeHandler}
                />
              </div> */}
            </Form.Group>
          </div>

          <div className="delivery-data-section">
            <h3>Final Delivery</h3>
            <span className="delivery-date">{formattedDate}</span>
          </div>

          {/* </div> */}
        </div>
      </div>

      {/*---------------------------- delivery footer start ---------------------------------- */}
      <div className="delivery-page-footer-main container-fluid">
        <div className="delivery-page-footer-body container">
          <DeliveryCostFooter
            addOnFeatureCost={addOnFeatureCost}
            standardCost={standardCost}
            fixedCost={fixedCost}
            duration={deliveryTime}
            finalCost={finalCost}
            platfromAddonCost={platfromAddonCost}
          />
          <DeliveryButton deliveryHandler={projectFromHandler} />
        </div>
      </div>

      {/*---------------------------- delivery footer end---------------------------------- */}
    </>
  )
}

const mapStateToProps = state => {
  const allModules = _.get(state.app, "allModules", [])
  const submitProjectLoading = _.get(state.app, "submitProjectLoading", [])
  const submittedProject = _.get(state.app, "submitProject", [])
  const submitProjectError = _.get(state.app, "submitProjectError", [])
  const requestedData = _.get(state.app, "requestData", [])
  const auth = _.get(state.user, "auth", false)
  const signInLoading = _.get(state.user, "signInLoading", false)
  const signedInUser = _.get(state.user, "signedIn")

  return {
    allModules,
    submitProjectLoading,
    submittedProject,
    submitProjectError,
    requestedData,
    auth,
    signInLoading,
    signedInUser,
  }
}

const mapDispatchToProps = dispatch => ({
  addModule: data => dispatch({type: "createModulecalled", payload: data}),
  getModules: data => dispatch({type: "moduleListcalled"}),
  addPorjcect: data =>
    dispatch({type: "submitProjectSagaCalled", payload: data}),
})

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCard)
