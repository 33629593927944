export let costCalculation = (item)=>{
    if(item){

    let cost = 0
    if(item.features){
      for(let x in item.features ){
        // cost +=x.feature.cost
        cost = cost +item.features[x].feature.cost
      }
      return cost
    }
    else
    return cost
}


  }
  
  export function stringTruncate(str, n) {
    return (str.length > n) ? str.slice(0, n-3) + '...' : str;
  }
  