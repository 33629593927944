import React from 'react'
import UserDashboard from '@components/dashboard/UserDashboard'


function DashboardPage() {
  return (
    <div>
      <UserDashboard />
    </div>
  )
}

export default DashboardPage