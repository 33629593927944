import React from 'react'
import DeliveryCard from '@components/deliveryPageComponents/DeliveryCard'

function DeliveryPage() {
  return (
    <div>
        <DeliveryCard />
    </div>
  )
}

export default DeliveryPage