import React from "react";
import { Card, Button, Form, Row, Col, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomButton from "@components/commonComponets/CustomButton";
import InputField from "@components/commonComponets/InputField";
import MInputField from "@components/commonComponets/MInputField";

function SignUpForm(props) {
  return (
    <div>
      <Card className="auth-card">
        <Card.Body style={{ padding: "0px" }}>
          <div className="auth-form-main">
            {props.alertShow && (
              <Alert
                className="mt-4 sign-up-alert"
                variant="success"
                dismissible
              >
                Your account has been created, Please login
              </Alert>
            )}

            <div className="signup-form-heading">
              <h1 className="form-heading-text">Create Account</h1>
            </div>
            <Form onSubmit={props.signUpHandler}>
              {/* <InputField
            value={props.fullName}
            setChangeHandler={props.setFullName}
             type={"text"} placeholder={"Full Name"} /> */}
              {/* <InputField value={props.email}
            required = {true}
            setChangeHandler = {props.setEmail}
             type={"email"} placeholder={"Email Address"} /> */}

              <MInputField
                label={"Email"}
                name={"email"}
                value={props.formData.emial}
                handleChange={props.handleFieldChange}
                fullWidth
                margin="normal"
                required={true}
                error={props.errors?.email && true}
                helperText={props.errors?.email}
              />

              <MInputField
                label={"Password"}
                name={"password"}
                type={props.showPassword ? "text" : "password"}
                value={props.formData.password}
                handleChange={props.handleFieldChange}
                fullWidth
                margin="normal"
                required={true}
                error={props.errors?.password && true}
                helperText={props.errors?.password}
                password={true}
                handleToggle={props.handleTogglePassword}
                showPassword={props.showPassword}
              />

              {/* <InputField value={props.password} 
            required = {true}
            setIsPasswordMatch = {props.setIsPasswordMatch}
            setChangeHandler = {props.setPassword}
            error = {props.passwordError}
            setError = {props.setPasswordError}
            confirmPassword={props.confirmPassword}

            type={"password"} placeholder={"Password"} /> */}

              <MInputField
                label={"Confirm Password"}
                name={"confirmPassword"}
                type={props.showConfirmPassword ? "text" : "password"}
                value={props.formData.confirmPassword}
                handleChange={props.handleFieldChange}
                fullWidth
                margin="normal"
                required={true}
                error={props.errors?.confirmPassword && true}
                helperText={props.errors?.confirmPassword}
                password={true}
                handleToggle={props.handleToggleConfirmPassword}
                showPassword={props.showConfirmPassword}
              />
              {/* <InputField value={props.confirmPassword} 
            required = {true}
            confirmPasswordError = {props.confirmPasswordError}
            name = {"confirmPassword"}
            isPasswordMatch = {props.isPasswordMatch}
            setChangeHandler = {props.setConfirmPassword}
            error = {props.confirmPasswordError}
            setError = {props.setConfirmPasswordError}

            type={"password"} placeholder={"Confirm Password"} /> */}

              <div className="sign-up-button mt-5">
                <CustomButton
                  loading={props.signUpLoading}
                  text={"Create Account"}
                  type={"submit"}
                />
              </div>

              <div className="divider my-3">
                <p>- OR -</p>
              </div>

              {/* <div className='google-auth-container'>
            <Button  className='google-auth-button'>
                Sign up with Google
            </Button>
        </div> */}

              <Row style={{ textAlign: "center" }}>
                <Col className="redirect-text">
                  Already have an account?{" "}
                  <Link
                    onClick={() => props.showModal()}
                    className="login-signup"
                  >
                    Login In
                  </Link>
                </Col>
              </Row>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default SignUpForm;
