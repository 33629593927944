import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NestedAccordion = () => {
  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckChange = (itemId) => {
    const isChecked = checkedItems.includes(itemId);

    if (isChecked) {
      setCheckedItems(checkedItems.filter((id) => id !== itemId));
    } else {
      setCheckedItems([...checkedItems, itemId]);
    }
  };

  const accordionData = [
    {
      id: 1,
      name: 'Parent Accordion 1',
      children: [
        {
          id: 2,
          name: 'Child Accordion 1',
          nestedChildren: [
            { id: 5, name: 'Nested Child 1' },
            { id: 6, name: 'Nested Child 2' },
          ],
        },
      ],
    },
    {
      id: 3,
      name: 'Parent Accordion 2',
      children: [
        {
          id: 4,
          name: 'Child Accordion 2',
          nestedChildren: [
            { id: 7, name: 'Nested Child 3' },
            { id: 8, name: 'Nested Child 4' },
          ],
        },
      ],
    },
  ];

  return (
    <div>
      {accordionData.map((item) => (
        <Accordion key={item.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{item.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedItems.includes(item.id)}
                  onChange={() => handleCheckChange(item.id)}
                />
              }
              label="Parent Checkbox"
              labelPlacement="start"
            />
            <div>
              {item.children &&
                item.children.map((child) => (
                  <Accordion key={child.id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>{child.name}</Typography>
                    </AccordionSummary>
                    {child.nestedChildren && (
                      <AccordionDetails>
                        <div>
                          {child.nestedChildren.map((nestedChild) => (
                            <FormControlLabel
                              key={nestedChild.id}
                              control={
                                <Checkbox
                                  checked={checkedItems.includes(nestedChild.id)}
                                  onChange={() => handleCheckChange(nestedChild.id)}
                                />
                              }
                              label={nestedChild.name}
                              labelPlacement="start"
                            />
                          ))}
                        </div>
                      </AccordionDetails>
                    )}
                  </Accordion>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
      <div>
        <strong>Checked Items:</strong>
        {checkedItems.map((itemId) => (
          <span key={itemId}>{itemId} </span>
        ))}
      </div>
    </div>
  );
};

export default NestedAccordion;
