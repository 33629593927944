import React from "react";
import { Navbar, Nav, NavDropdown, Modal, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import profileavatar from "@assets/images/authImages/profileavatar.png";
import defaultAvatar from "@assets/images/authImages/default.png";
import { useState, useEffect } from "react";
import LoginCard from "@components/authComponents/LoginCard";
import SignUpCard from "@components/authComponents/SignUpCard";
import ForgotCard from "@components/authComponents/ForgotCard";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import * as _ from "lodash";
import Loader from "@components/commonComponets/Loader/Loader";
import Logo from "@assets/images/BuilderxLogo.png";
import { Login, Logout } from "@mui/icons-material";
import { ConfirmPopUp } from "../popups/PopUpCards";

import { Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  useTheme,
  Avatar,
  MenuItem,
  Menu,
  Button,
  Tooltip,
  createTheme,
} from "@material-ui/core";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";

// Css for nav bar

const navbarCustomDropdownStyle = {
  marginLeft: "auto",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    textDecoration: "none",
  },
  darkTitle: {
    color: "white",
  },
  drawer: {
    width: 250,
  },
  desktopNav: {
    display: "flex",
    alignItems: "center",
    // color: theme.palette.common.black,
    color: "white",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "#887CD4",
    color: theme.palette.common.black,
    padding: "5px 30px",
  },

  navbrand: {
    justifyContent: "space-between",
  },
}));

function Header(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [darkMode, setDarkMode] = useState(false);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const { userLogout } = props;
  let navigate = useNavigate();
  const auth = useSelector((state) => state.user);
  let isAuthenticated = auth && auth.auth;
  let signInStart = useSelector((state) => state.user);
  let signInLoading = signInStart && signInStart.signInLoading;
  const [loginShow, setShowLogin] = useState(false);

  const handleLoginClose = () => setShowLogin(false);
  const handleLoginShow = () => {
    setShowLogin(true);
    setShowSignUp(false);
    setShowForgot(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isAuthenticated) {
      setShowLogin(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const [signUpShow, setShowSignUp] = useState(false);
  const handleSignUpClose = () => setShowSignUp(false);

  const handleSignUpShow = () => {
    setShowSignUp(true);
    setShowLogin(false);
  };

  const [forgotShow, setShowForgot] = useState(false);
  const handleForgotClose = () => setShowForgot(false);

  const handleForgotShow = () => {
    setShowForgot(true);
    setShowLogin(false);
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleConfirmClose = () => setShowConfirmModal(false);
  const handleConfirmShow = () => setShowConfirmModal(true);

  const handleLogout = () => {
    userLogout({ navigate })
    setAnchorEl(null);
    handleConfirmClose();
  };

  return (
    <>
        <ConfirmPopUp 
    message={"Are you sure you want to log out?"}
    show={showConfirmModal}
    handleClose={handleConfirmClose}
    handleLogout = {handleLogout}
    />
      {/* -------------------------------------Auth Modals Start------------------------------------- */}

      <Modal
        className="auth-modal"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        show={loginShow}
        onHide={handleLoginClose}
      >
        {/* <Modal.Body> */}
        {/* {signInLoading && <Loader />} */}

        <LoginCard
          handleClose={handleLoginClose}
          setShowLogin={setShowLogin}
          showModal={handleSignUpShow}
          showForgot={handleForgotShow}
        />
        {/* </Modal.Body> */}
      </Modal>

      <Modal
        className="auth-modal"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        show={signUpShow}
        onHide={handleSignUpClose}
      >
        {/* <Modal.Body> */}
        <SignUpCard
          handleClose={handleSignUpClose}
          setShowSignUp={setShowSignUp}
          showModal={handleLoginShow}
          setShowLogin ={setShowLogin}
        />
        {/* </Modal.Body> */}
      </Modal>

      <Modal
        className="auth-modal"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        show={forgotShow}
        onHide={handleForgotClose}
      >
        {/* <Modal.Body> */}
        <ForgotCard
          handleClose={handleForgotClose}
          showModal={handleLoginShow}
        />
        {/* </Modal.Body> */}
      </Modal>

      {/* ----------------------------Auth Modal End------------------------------------------------------ */}

      {signInLoading && <Loader />}

      <div className="main-header">
        <AppBar position="static" className="header">
          <Toolbar className={classes.navbrand}>
            <Button
              className="brand-logo-button"
              component={RouterLink}
              to="/"
              color="inherit"
            >
              <img src={Logo} alt="Logo" className="builder-logo" />
            </Button>
            {!isAuthenticated ? (
              <Button
              startIcon={<Login />}
              variant="outlined"
                onClick={() => {
                  setShowLogin(true);
                  setAnchorEl(null);
                }}
                className="login-button"
                
              >
                Login
              </Button>
            ) : (
              <Button
              variant="outlined"
              startIcon={<Logout />}
                className="logout-button"
                onClick={() => {
                  handleConfirmShow()
                }}
              >
                Logout
              </Button>
            )}
             {/* <Button  variant="outlined" startIcon={<Login />}>
      Login
    </Button> */}

            {/* <Tooltip title="Open settings">
              <IconButton onClick={handleProfileMenuOpen} color="inherit">
                <Avatar src={isAuthenticated ? profileavatar : defaultAvatar}
                className="profile-logo"
                 alt="Profile"  />
              </IconButton>
            </Tooltip> */}
            {/* <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              getContentAnchorEl={null}
              open={Boolean(anchorEl)}
              onClose={handleProfileMenuClose}
            >
                <MenuItem disabled= {!isAuthenticated} onClick={()=>{
                  navigate("/dashboard")
                  setAnchorEl(null)
                }}>Dashboard</MenuItem>
            
              {!isAuthenticated ? (
                <MenuItem  onClick={() => {
                  setShowLogin(true)
                  setAnchorEl(null);

                }}
                  
                  >Login</MenuItem>
                ):
                <MenuItem onClick={() => {
                  userLogout({ navigate })
                  setAnchorEl(null);
                }}>Logout</MenuItem>
              }
            </Menu> */}
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const allModules = _.get(state.module, "allModules", []);

  return { allModules };
};

const mapDispatchToProps = (dispatch) => ({
  userLogout: (data) => dispatch({ type: "logoutSagaCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
