import React from 'react';
import { ThemeProvider, Typography, Button } from '@mui/material';

function MyComponent() {
  return (
      <div>
        <Typography variant="h1">Heading</Typography>
        <Button variant="contained" color="primary">
          Submit
        </Button>
      </div>
  );
}

export default MyComponent;
