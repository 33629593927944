import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Card, Modal } from "react-bootstrap";
import img1 from "@assets/images/authImages/img1.png";
import SignUpForm from "@components/authComponents/SignUpForm";
import { useState } from "react";
import * as _ from "lodash";
import { SuccessPopUp } from "../popups/PopUpCards";
import CustomCloseButton from "../commonComponets/CustomCloseButton";

function SignUpCard(props) {
  const { callRegister, signUpLoading } = props;
  const [show, setShow] = useState(false);
  // const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // useEffect(() => {

  //   if(password !== confirmPassword){
  //     setIsPasswordMatch(false)
  //   }else{
  //     setIsPasswordMatch(true)
  //     setConfirmPasswordError("")
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [confirmPassword, password]);

  const validateForm = () => {
    const newErrors = {};

    if (formData.email.trim() === "") {
      newErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (formData.password.trim() === "") {
      newErrors.password = "Password is required";
    }else if(formData.password.trim().length <8){
      newErrors.password = "Password must be at least 8 characters long";

    }
    if (formData.confirmPassword.trim() !== formData.password.trim()) {
      newErrors.confirmPassword = "Passwords do not match.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const signUpHandler = (event) => {
    event.preventDefault();
    if(validateForm()){
      let data = {
                email: formData.email,
                password:formData.password,
                role:'User',
                loginType:"email",
                setShow,
                resetState,
                setShowLogin:props.setShowLogin,
                showModal:props.showModal,
                setProjectFromShow:(props.setProjectFromShow && props.setProjectFromShow)
              };
        callRegister(data)


    }

  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Remove error when typing in a field
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const resetState = () => {
    setFormData({
      email: "",
      password: "",
      confirmPassword: "",
    });
    
  };
  const handleClose = () => setShow(false);

  return (
    <div>
      <Modal
        className="submit-success-popup"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <SuccessPopUp
          message={"Your account has been created, Please login"}
          button_text={"Close"}
          cancel={handleClose}
        />
      </Modal>
      <Card className="auth-main-card">
        <CustomCloseButton handleClose={props.handleClose} />
        <Card.Body style={{ padding: "0px" }}>
          <div className="auth-card-image">
            {/* <Card.Img  src={img2} /> */}
            <img alt="sign up " src={img1} />
          </div>
          <div className="auth-container">
            <SignUpForm
              handleFieldChange={handleFieldChange}
              errors={errors}
              formData={formData}
              showPassword={showPassword}
              showConfirmPassword={showConfirmPassword}
              signUpLoading={signUpLoading}
              handleTogglePassword={handleTogglePassword}
              handleToggleConfirmPassword={handleToggleConfirmPassword}
              showModal={props.showModal}
              signUpHandler={signUpHandler}

              //  setIsPasswordMatch = {setIsPasswordMatch}
              //  isPasswordMatch = {isPasswordMatch}
              //  confirmPasswordError = {confirmPasswordError}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

const mapStateToProps = ({ app = {}, user = {} }) => {
  const signUpLoading = _.get(user, "signUpLoading", false);
  const signUpError = _.get(user, "signUpError", undefined);
  const signedUp = _.get(user, "signedUp");
  const auth = _.get(user, "auth", false);

  return { signUpLoading, signUpError, signedUp, auth };
};

const mapDispatchToProps = (dispatch) => ({
  callRegister: (data) => dispatch({ type: "userSignupcalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpCard);
