import React from 'react'
import { Card,Form,Row,Col} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import img3 from '@assets/images/authImages/img3.png'
import InputField from '@components/commonComponets/InputField'
import CustomButton from '@components/commonComponets/CustomButton'
import CustomCloseButton from '@components/commonComponets/CustomCloseButton'


function ForgotCard(props) {
  return (
    <Card className='auth-main-card' >
    <Card.Body style={{ padding:"0px" }} >
        <div className='auth-card-image'>
 {/* <Card.Img  src={img2} /> */}
 <img alt='forgot' src={img3} />
 </div>
<div className='auth-container'>
<Card className='auth-card' >

    <CustomCloseButton handleClose = {props.handleClose} />
      <Card.Body style={{ padding:"0px" }} >
        <div className='auth-form-main'>
            <div className='form-heading'>
                <h1 className='form-heading-text'>
                    Forgot your Password?
                </h1>
            </div>
        <Form>
            <div className='forgot-inst'>Please enter your email</div>
            <InputField type={"email"} placeholder={"Email Address"} />

        <div className='forgot-button-div mt-5'>

        <CustomButton text={"Send Reset Link"} type="submit"/>
        </div>

        <Row style={{ textAlign:"center" }}>
            <Col className="redirect-text">
                Back to{" "}
                <Link onClick={()=>props.showModal()} className="login-signup">
                    Sign In
                </Link>
            </Col>
        </Row>
    </Form>
        </div>
        
      </Card.Body>
    </Card>
</div>
</Card.Body>
</Card>
  )
}

export default ForgotCard