import React from 'react'
import "./costFooter.css"
// import { costCalculation } from '../utilities/untilitiesfunctions'
import {useEffect } from 'react'
import { Button } from '@material-ui/core'
import { DOLLAR_SYMBOL } from '../../constants/Constans'


export function DeliveryCostFooter(props) {

  // const {addOnFeatureCost} = props


  useEffect(()=>{

  
  }, [])

  // const[ totalProjectCost, setTotalProjectCost] = useState(parseFloat(props.fixedCost)+parseFloat(props.standardCost )+ props.addOnFeatureCost)

  return (
   
<div className='cost-calculation'>
  {/* <div className='plan'>
  <p className='cost-title'>
    Customization Cost
  </p>
  <p className='feature-cost'>
  $ 150000.00
  </p>
  </div> */}


<div className='plan'>
<p className='cost-title'>
Standard Cost
</p>
<p className='feature-cost'>
{`${DOLLAR_SYMBOL}  ${props.standardCost}`}
</p>
</div>

  <samp className='operator'> + </samp>

  <div className='plan'>
  <p className='cost-title'>
    Fixed Cost
  </p>
  <p className='feature-cost'>
  {`${DOLLAR_SYMBOL} ${props.fixedCost}`}
  </p>
  </div>

  <span className='operator'> + </span>
  <div className='plan'>
  <p className='cost-title'>
    Add-On Cost
  </p>
  <p className='feature-cost'>
  {`${DOLLAR_SYMBOL} ${props.addOnFeatureCost+((props.platfromAddonCost)!=undefined?props.platfromAddonCost:0)}`}
  </p>
  </div>




<span className='operator'> = </span>

<div className='plan'>
<p className='cost-title'>
Total Cost
</p>
<p className='feature-cost'>
{`${DOLLAR_SYMBOL} ${props.finalCost? props.finalCost : parseFloat(props.fixedCost)+parseFloat(props.standardCost )+ (props.addOnFeatureCost+((props.platfromAddonCost)!=undefined?props.platfromAddonCost:0))}`}
</p>
</div>

<div class="vl"></div>

<div className='plan'>
  <p className='cost-title'>
    Duration
  </p>
  <p className='feature-cost'>
  {props.duration? props.duration : "20 Weeks"}
  </p>
  </div>
</div>

  )}

  export function DeliveryButton(props){

    return(
      <div className='delivery-plan-btn'>
      <Button variant='contained' onClick={props.deliveryHandler} color='primary'>Plan Delivery</Button>
    </div>
    )
    }