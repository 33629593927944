import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#887CD4', // Customize the primary color
    },
    secondary: {
      main: '#f50057', // Customize the secondary color
    },

    danger:
    {
      main:'#d32f2f'
    },
    lightRed: {
      main: '#ff5252',
    }
    // Customize additional colors if needed
  },
  typography: {
    fontFamily: 'Roboto, sans-serif', // Customize the default font family
    fontSize: 16, // Customize the default font size
    // Customize additional typography properties such as fontWeight, lineHeight, etc.
  },
  spacing: 8, // Customize the default spacing between components
  breakpoints: {
    values: {
      xs: 0, // Customize the extra small screen breakpoint
      sm: 600, // Customize the small screen breakpoint
      md: 960, // Customize the medium screen breakpoint
      lg: 1280, // Customize the large screen breakpoint
      xl: 1920, // Customize the extra large screen breakpoint
    },
  },
  shadows: [
    'none', // Customize the default shadow
    '0px 2px 4px rgba(0, 0, 0, 0.1)', // Customize additional shadows
  ],
  shape: {
    borderRadius: 8, // Customize the border radius for components
  },
  transitions: {
    easing: {
      // Customize the easing function for transitions
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    duration: {
      // Customize the duration of transitions
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  overrides: {
    // Customize specific components' styles here if needed
  },
});

export default theme;
