import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  moduleList: undefined,
  createModuleLoading: false,
  createModuleError: "",
  moduleListLoading: false,
  moduleListError: undefined,
  editModuleLoading: false,
  editModuleError: undefined,
  deleteModuleLoading: false,
  moduleDetailsLoading: false,
  moduleDetailsError: undefined,
  moduleDetails: undefined,
  allModules: [],
  featureList: undefined,
  featureListLoading: false,
  featureListError: undefined,
  allFeatures: [],
  featuresByTemplate: undefined,
  featuresByTemplateLoading: false,
  featuresByTemplateError: undefined,
  requestData: [],
  submitProjectLoading: false,
  submitProjectError: undefined,
  submitProject: [],
  customFeatureLoading: false,
  allcustomFeatures: [],
  customFeatureError: undefined,
  customFeatures: [],
  getSubmitProjectLoading: false,
  getSubmitProjectError: undefined,
  domainLoading: false,
  allDomains: [],
  domaintError: undefined,
  prebuiltFeatureIds: undefined,
  selectedFeatureIds: [],
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    allStateReset: (state, acton) => {
      return {
        moduleListLoading: false,
        moduleListError: undefined,
        editModuleLoading: false,
        editModuleError: undefined,
        moduleDetailsError: undefined,
        featureListLoading: false,
        featureListError: undefined,
        featuresByTemplateLoading: false,
        featuresByTemplateError: undefined,
        submitProjectLoading: false,
        submitProjectError: undefined,
        submitProject: [],
        customFeatureLoading: false,

        customFeatureError: undefined,
        customFeatures: [],
        getSubmitProjectLoading: false,
        getSubmitProjectError: undefined,
      }
    },
    createSuccess: (state, action) => {
      state.createModuleLoading = false
      state.allModules = [...state.allModules, action.payload]
      state.createModuleError = undefined
    },
    createTrigger: (state, action) => {
      state.createModuleLoading = action.payload
    },
    createFail: (state, action) => {
      state.createModuleError = action.payload
    },
    editSuccess: (state, action) => {
      state.moduleListLoading = false
      state.editModuleLoading = false

      const {id, data} = action.payload
      state.allModules = state.allModules.map(module => {
        if (module.id === id) {
          return {...module, ...data}
        }
        return module
      })
    },

    editTrigger: (state, action) => {
      state.moduleListLoading = true
      state.moduleListError = undefined
    },
    editFail: (state, action) => {
      state.editModuleError = action.payload
    },

    listTrigger: (state, action) => {
      state.moduleListLoading = true
      state.allModules = undefined
      state.moduleListError = undefined
    },
    listSuccess: (state, action) => {
      state.moduleListLoading = false
      state.allModules = action.payload
      state.moduleListError = undefined
    },
    listFail: (state, action) => {
      state.moduleListLoading = false
      state.allModules = undefined
      state.moduleListError = action.payload
    },
    deleteTrigger: (state, action) => {
      state.moduleListLoading = true
      state.moduleListError = undefined
    },
    deleteSuccess: (state, action) => {
      state.moduleListLoading = false
      state.allModules = state.allModules.filter(
        module => module.id !== action.payload
      )
    },

    deleteFail: (state, action) => {
      state.moduleListLoading = false
      state.moduleListError = action.payload
    },

    moduleDetailsStart: (state, action) => {
      return {
        ...state,
        moduleDetailsLoading: true,
        moduleDetails: undefined,
        moduleDetailsError: undefined,
      }
    },

    moduleDetailsSuccess: (state, action) => {
      return {
        ...state,
        moduleDetailsLoading: false,
        moduleDetails: action.payload,
        moduleDetailsError: undefined,
      }
    },

    moduleDetailsFail: (state, action) => {
      return {
        ...state,
        moduleDetailsLoading: false,
        moduleDetails: undefined,
        moduleDetailsError: action.payload,
      }
    },

    featureListTrigger: (state, action) => {
      state.featureListLoading = true
      state.allFeatures = undefined
      state.featureListError = undefined
    },
    featureListSuccess: (state, action) => {
      state.featureListLoading = false
      state.allFeatures = action.payload
      state.featureListError = undefined
    },
    featureListFail: (state, action) => {
      state.featureListLoading = false
      state.allFeatures = undefined
      state.featureListError = action.payload
    },

    // -------------------Features by Template id start--------------------------------------
    featuresByTemplateTrigger: (state, action) => {
      state.featuresByTemplateLoading = true
      state.featuresByTemplate = undefined
      state.featuresByTemplateError = undefined
    },
    featuresByTemplateSuccess: (state, action) => {
      state.featuresByTemplateLoading = false
      state.featuresByTemplate = action.payload
      state.featuresByTemplateError = undefined
    },
    featuresByTemplateFail: (state, action) => {
      state.featuresByTemplateLoading = false
      state.featuresByTemplate = undefined
      state.featuresByTemplateError = action.payload
    },

    addSelectedFeatureIDs: (state, action) => {
      state.selectedFeatureIds = [...state.selectedFeatureIds, action.payload]

      // return state
    },

    delSelectedFeatureIDs: (state, action) => {
      return {
        ...state,
        selectedFeatureIds: state.selectedFeatureIds?.filter(
          (f, index) => f?.id !== action.payload
        ),
      }
    },

    // -------------------Features by Template id End--------------------------------------

    deleteCustomFeature: (state, action) => {
      state.allcustomFeatures = state.allcustomFeatures.filter(
        (f, index) => index !== action.payload
      )
    },

    customFeatureList: (state, action) => {},

    addRequestData: (state, action) => {
      state.requestData = action.payload
    },

    // -----------------------------Submit Project----------------------------------------//

    submitProjectStart: (state, action) => {
      return {
        ...state,
        submitProjectLoading: true,

        submitProject: undefined,
        submitProjectError: undefined,
      }
    },

    submitProjectSuccess: (state, action) => {
      return {
        ...state,
        submitProjectLoading: false,
        submitProject: action.payload,
        submitProjectError: undefined,
      }
    },

    submitProjectFail: (state, action) => {
      return {
        ...state,
        submitProjectLoading: false,
        submitProject: undefined,
        submitProjectError: action.payload,
      }
    },

    getSubmitProjectStart: (state, action) => {
      return {
        ...state,
        getSubmitProjectLoading: true,

        submitProject: undefined,
        getSubmitProjectError: undefined,
      }
    },

    getSubmitProjectSuccess: (state, action) => {
      return {
        ...state,
        getSubmitProjectLoading: false,
        submitProject: action.payload,
        getSubmitProjectError: undefined,
      }
    },

    getSubmitProjectFail: (state, action) => {
      return {
        ...state,
        getSubmitProjectLoading: false,
        submitProject: undefined,
        getSubmitProjectError: action.payload,
      }
    },

    // ------------------------------Custom Feature-----------------------------------------//

    // createCustomFeature: (state, action) => {
    //   state.allcustomFeatures.push(action.payload);

    // },

    createCustomFeatureStart: (state, action) => {
      return {
        ...state,
        customFeatureLoading: true,

        allcustomFeatures: undefined,
        customFeatureError: undefined,
      }
    },

    createCustomFeatureSuccess: (state, action) => {
      return {
        ...state,
        customFeatureLoading: false,
        customFeatures: [...state.customFeatures, action.payload],
        customFeatureError: undefined,
      }
    },

    createCustomFeatureFail: (state, action) => {
      return {
        ...state,
        customFeatureLoading: false,

        customFeatureError: action.payload,
      }
    },

    getCustomFeatureStart: (state, action) => {
      return {
        ...state,
        customFeatureLoading: true,

        customFeatures: undefined,
        customFeatureError: undefined,
      }
    },

    getCustomFeatureSuccess: (state, action) => {
      return {
        ...state,

        customFeatureLoading: false,
        customFeatures: action.payload,
        customFeatureError: undefined,
      }
    },

    getCustomFeatureFail: (state, action) => {
      return {
        ...state,
        customFeatureLoading: false,
        customFeatures: [],
        customFeatureError: action.payload,
      }
    },

    deleteCustomFeatureTrigger: (state, action) => {
      return {
        ...state,
        customFeatureLoading: true,
        customFeatures: [],
        customFeatureError: undefined,
      }
    },
    deleteCustomFeatureSuccess: (state, action) => {
      state.customFeatureLoading = false
      state.customFeatures = state.customFeatures.filter(
        feature => feature.id !== parseInt(action.payload)
      )
      state.customFeatureError = undefined
    },
    deleteCustomFeatureFail: (state, action) => {
      return {
        ...state,
        customFeatureLoading: false,
        customFeatures: [],
        customFeatureError: action.payload,
      }
    },

    // ---------------------------------------------Domain------------------------------------------//

    getDomainsStart: (state, action) => {
      return {
        ...state,
        domainLoading: true,

        allDomains: undefined,
        domaintError: undefined,
      }
    },

    getDomainsSuccess: (state, action) => {
      return {
        ...state,

        domainLoading: false,
        allDomains: action.payload,
        domaintError: undefined,
      }
    },

    getDomainsFail: (state, action) => {
      return {
        ...state,
        domainLoading: false,
        allDomains: [],
        domaintError: action.payload,
      }
    },
  },
})

export default appSlice.reducer
export const {
  createFail,
  createSuccess,
  createTrigger,
  editFail,
  editSuccess,
  editTrigger,
  deleteSuccess,
  deleteFail,
  deleteTrigger,
  listTrigger,
  listSuccess,
  listFail,
  allStateReset,
  moduleDetailsStart,
  moduleDetailsSuccess,
  moduleDetailsFail,
  featureListTrigger,
  featureListSuccess,
  featureListFail,
  createCustomFeature,
  featuresByTemplateFail,
  featuresByTemplateSuccess,
  featuresByTemplateTrigger,
  deleteCustomFeature,
  addRequestData,
  submitProjectStart,
  submitProjectFail,
  submitProjectSuccess,
  createCustomFeatureStart,
  createCustomFeatureFail,
  createCustomFeatureSuccess,
  getSubmitProjectStart,
  getSubmitProjectSuccess,
  getSubmitProjectFail,
  getDomainsStart,
  getDomainsSuccess,
  getDomainsFail,
  getCustomFeatureStart,
  getCustomFeatureFail,
  getCustomFeatureSuccess,
  deleteCustomFeatureTrigger,
  deleteCustomFeatureSuccess,
  deleteCustomFeatureFail,
  addSelectedFeatureIDs,
  delSelectedFeatureIDs,
} = appSlice.actions
