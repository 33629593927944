import React from "react"
import {connect} from "react-redux"
import {useEffect, useState} from "react"
import ServiceCard from "@components/ServiceCard"
import {Col, Row} from "react-bootstrap"
import HomeSidebar from "@components/navBar/HomeSidebar"
import * as _ from "lodash"
import Loader from "@components/commonComponets/Loader/Loader"
import {Grid} from "@mui/material"
import Scrollbars from "react-custom-scrollbars-2"

function HomeComponent(props) {
  const {
    getModules,
    getDomains,
    allModules,
    allDomains,
    getFeatures,
    moduleListLoading,
    auth,
    resetLoadings,
  } = props

  useEffect(() => {
    window.scrollTo(0, 0)
    getModules()
    getFeatures()
    getDomains()
    resetLoadings()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getModules()
    getFeatures()
    getDomains()
    // window.location.reload()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])
  const allModuleslist = allModules?.list
  const [filterTemplates, setFilterTemplates] = useState(allModuleslist)
  const [templates, setTemplates] = useState([])
  useEffect(() => {
    setFilterTemplates(allModuleslist)
  }, [allModuleslist])
  // console.log(filterTemplates, "filterTemplates")
  const domainHandler = (e, data) => {
    if (e.target.checked) {
      let temp = [...templates]
      for (let x of data.templates) {
        let dt = allModuleslist.find(item => item.id === x.template.id)
        // if (!temp.some(obj => obj.id === x.template.id)) {
        temp.push(dt)
        // }
      }
      let uniqueTemp = temp.filter((obj, index, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === index
      })
      setFilterTemplates(uniqueTemp)
      setTemplates(temp)
    } else {
      let temp = [...templates]
      for (let x of data.templates) {
        const indexToRemove = temp.findIndex(obj => obj.id === x.template.id)
        if (indexToRemove !== -1) {
          temp.splice(indexToRemove, 1)
        }
      }
      let uniqueTemp = temp.filter((obj, index, arr) => {
        return arr.map(mapObj => mapObj.id).indexOf(obj.id) === index
      })
      setFilterTemplates(uniqueTemp?.length ? temp : allModuleslist)
      //   setFilterTemplates(temp.length ? temp : allModules);
      setTemplates(temp)
    }
  }

  return (
    <div className="container-fluid">
      {moduleListLoading && <Loader />}

      <Row>
        <Col className="side-bar-col" lg={3}>
          <HomeSidebar domainHandler={domainHandler} domainsList={allDomains} />
        </Col>

        <Col className="templates-main-row" md={12} lg={9}>
          <div className="home-page-heading">
            <h2>Choose a template </h2>
          </div>
          {/* <Row className="ms-1"> */}
          <Scrollbars
            style={{height: "calc(100vh - 135px)"}}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
          >
            <div className="home-template-card" style={{marginBottom: "30px"}}>
              {filterTemplates?.map((item, index) => (
                // <Col
                //   key={index}
                //   className="home-template-card"
                //   xs={12}
                //   md={4}
                //   lg={4}
                // >
                <ServiceCard key={index} item={item} />
                // </Col>
              ))}
            </div>
          </Scrollbars>
          {/* </Row> */}
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = state => {
  const allModules = _.get(state.app, "allModules", [])
  const allFeatures = _.get(state.app, "allFeatures", [])
  const createModuleLoading = _.get(state.app, "createModuleLoading", false)
  const moduleListLoading = _.get(state.app, "moduleListLoading", false)
  const editModuleLoading = _.get(state.app, "editModuleLoading", false)
  const deleteModuleLoading = _.get(state.app, "deleteModuleLoading", false)
  const domainLoading = _.get(state.app, "domainLoading", false)
  const allDomains = _.get(state.app, "allDomains", [])
  const domaintError = _.get(state.app, "domaintError", undefined)
  const auth = _.get(state.user, "auth", false)

  return {
    allModules,
    createModuleLoading,
    moduleListLoading,
    allDomains,
    domainLoading,
    domaintError,
    editModuleLoading,
    deleteModuleLoading,
    allFeatures,
    auth,
  }
}

const mapDispatchToProps = dispatch => ({
  getModules: data => dispatch({type: "moduleListcalled"}),
  getProjects: data => dispatch({type: "getsubmittedProjectSagaCalled"}),
  getFeatures: data => dispatch({type: "featureListcalled"}),
  getFeaturesByTemId: data =>
    dispatch({type: "featuresByTemplateSagaCalled", payload: data}),
  getDomains: data => dispatch({type: "getDomainsSagaCalled"}),
  resetLoadings: data => dispatch({type: "resetLoadingSagaCalled"}),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent)
