import React from 'react'
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
function MInputField(props) {
  return (
    <div>
        <TextField 
        multiline = {props.multilies}
        rows={props.multilies? props.rows : null}
          type={props.type}
        label={props.label}
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
        fullWidth
        margin="normal"
        required = {props.required}
        error={props.error}
        helperText={props.helperText}
        className={`custom-from-control ${props.fSize}`}
        InputProps={props.password &&{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={props.handleToggle}>
                {props.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        />
    </div>
  )
}

export default MInputField