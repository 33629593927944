import { Button } from '@material-ui/core'
import React from 'react'
import {Spinner} from 'react-bootstrap'

function CustomButton(props) {
  // const {text}  = props
  return (
   
      <Button variant='contained' color="primary" disabled={props.loading} type={props.type} className="w-100">
        {props.loading?
         <div className='d-flex justify-content-center align-items-center'>
         <Spinner animation="border" style={{width: "1.2rem", height:"1.2rem"} }size="sm" role="status" />
           <span className="mx-3">Loading...</span>
         </div>
         :props.text
         }
       
      
        
        </Button>
  )
}

export default CustomButton