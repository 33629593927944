import './Navbar.css';
import Header from './Header';

function Navbar(props) {
  return (
    <>
    <Header />
    </>
  );
}

export default Navbar;
