import { configureStore} from "@reduxjs/toolkit";

import createSagaMiddleware from 'redux-saga';
import { combineReducers } from "redux";
import user from './userredux'
import app from './appredux'


import rootSaga from './sagaIndex';
import {
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";


const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["user","app"],
};

const reducer = combineReducers({
    user,
    app,
  
  });

  const persistedReducer = persistReducer(persistConfig, reducer);
let sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store)

export { persistor, store };
