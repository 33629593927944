import React from 'react'
import  HomeComponent from '@components/HomeComponent'


function HomePage() {



  return (
<>
      <HomeComponent />

    </>
   
  )
}

export default HomePage