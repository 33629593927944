import { IconButton } from '@material-ui/core'
import { ClosedCaption, HighlightOff } from '@material-ui/icons'
import React from 'react'
import { Button } from 'react-bootstrap'

function CustomCloseButton(props) {
  return (
    <div className='popup-close-btn-container'>
    <IconButton className='custom-close-btn' fontSize={"25"}  onClick={props.cancel} aria-label="close">
  <HighlightOff fontSize={"25"} />
</IconButton>   
    </div>
  )
}

export default CustomCloseButton