import React from 'react';
import NotFoundImage from "@assets/images/404.png"

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="not-found-heading">Oops! Page Not Found</h1>
        <p className="not-found-message">
          We're sorry, but the page you are looking for does not exist.
        </p>
        <div className="not-found-animation">
          <div className="not-found-circle"></div>
          <div className="not-found-circle"></div>
          <div className="not-found-circle"></div>
        </div>
        <img
          className="not-found-image"
          src={NotFoundImage}
          alt="404 Error"
        />
        <p className="not-found-suggestion">
          You may want to go back to our{' '}
          <a href="/">homepage</a> or explore other sections.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
