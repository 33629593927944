import { TextField } from '@material-ui/core'
import React from 'react'
import { FormControl,Form } from 'react-bootstrap'

function InputField(props) {
    const{type, placeholder,multiline} = props
  return (
    <div>
    <Form.Group className="mt-4 custom-field" >
    {!multiline &&  <TextField
    
      as = {props.as && props.as}
      rows={props.rows&& props.rows}
      required = {props.required ? true :false}
      value={props.value} 
      onChange={(event) => {
        props.setError&& props.setError("")
        props.setChangeHandler(event.target.value)
      }} 
   
    isInvalid = {props.name === "confirmPassword" &&!props.isPasswordMatch}
      
      type={type} placeholder={placeholder} className={`custom-from-control ${props.fSize}`} />}
      {multiline &&   <TextField
    
    as = {props.as && props.as}
    rows={props.rows&& props.rows}
    required = {props.required ? true :false}
    value={props.value} 
    onChange={(event) => {
      props.setError&& props.setError("")
      props.setChangeHandler(event.target.value)
    }} 
 multiline
 rows={4}
  isInvalid = {props.name === "confirmPassword" &&!props.isPasswordMatch}
    
    type={type} placeholder={placeholder} className={`custom-from-control ${props.fSize}`} />}

      {props.required && <span className="required-asterisk text-danger" style={{position:"absolute",right:"1px"}}>*</span>}
            {props.error && <Form.Text className="text-danger">{props.error}</Form.Text> }
      
      </Form.Group>
    </div>
  )
}

export default InputField