import {Button, IconButton} from "@material-ui/core"
import {
  AddCircle,
  AddIcCallOutlined,
  DeleteForeverOutlined,
  DeleteOutline,
  DeleteOutlineOutlined,
  Info,
  InfoOutlined,
  InfoRounded,
  PlusOne,
} from "@material-ui/icons"
import React, {useEffect, useState} from "react"
import Scrollbars from "react-custom-scrollbars-2"
import {stringTruncate} from "./utilities/untilitiesfunctions"

function CustomFeatureCard(props) {
  let {filteredAdminFeatures} = props
  const [filteredfeatures, setfilteredfeatures] = useState()
  let getFeatureCount = () => {
    let count =
      (filteredAdminFeatures?.length || 0) + (filteredfeatures?.length || 0)
    return count
  }
  useEffect(() => {
    setfilteredfeatures(
      props.customFeatures?.filter(customFeature => {
        // console.log("Current custom feature:", customFeature)
        return customFeature?.template?.some(template => {
          // console.log("Current template:", template)
          // console.log("props.templateId:", props.templateId)
          // console.log(template?.template?.id, "template.template.id")
          return (
            template?.template && template?.template?.id == props?.templateId
          )
        })
      })
    )
  }, [props.customFeatures])
  // console.log(filteredfeatures, "filteredfeatures")
  return (
    <div className="feature-section">
      <div className="sub-section">
        <div className="feature-header">
          <p>Added Features({getFeatureCount()})</p>
          <Button
            startIcon={<AddCircle />}
            varient="outlined"
            className="custom-feature-btn"
            onClick={() => props.setShow(true)}
            color="primary"
          >
            Custom feature
          </Button>
        </div>

        <hr className="feature-divider" />
        <Scrollbars
          style={{height: "calc(100vh - 135px)"}}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
        >
          <div
            style={{
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingBottom: "50px",
            }}
          >
            {/* {props.allFeatures&& props.allFeatures?.map(item=> )} */}
            {filteredAdminFeatures &&
              filteredAdminFeatures?.map((item, index) => (
                <>
                  <div key={index} className="feature-container">
                    <div className="title-price-div">
                      <p className="feature-title">{item?.name}</p>
                      {/* <p className="feature-price"> ₹ ---</p> */}
                    </div>
                    <div className="feature-actions">
                      <IconButton
                        size="small"
                        onClick={() => props.viewDetails(item, false)}
                        className="custom-feature-detail-button"
                        style={{marginRight: "10px"}}
                      >
                        <InfoOutlined fontSize="small" />
                      </IconButton>
                      <IconButton
                        className="custom-feature-detail-button"
                        onClick={() =>
                          props.deleteCustomFeature(item?.id, "Admin")
                        }
                        size="small"
                        style={{marginRight: "0px"}}
                      >
                        <DeleteOutline fontSize="small" />
                      </IconButton>
                    </div>
                  </div>
                  <hr className="feature-divider" />
                </>
              ))}
            {props.filteredAdminFeatures?.length < 0 && (
              <hr className="feature-divider" />
            )}

            {filteredfeatures?.map((item, index) => (
              <>
                <div key={index} className="feature-container">
                  <div className="title-price-div">
                    <p className="feature-title">
                      {stringTruncate(item.name, 35)}
                    </p>
                    {/* <p className="feature-price"> ₹ ---</p> */}
                  </div>
                  <div className="feature-actions">
                    <IconButton
                      size="small"
                      onClick={() => props.viewDetails(item, true)}
                      className="custom-feature-detail-button"
                      style={{marginRight: "10px"}}
                    >
                      <InfoOutlined fontSize="small" />
                    </IconButton>
                    <IconButton
                      className="custom-feature-detail-button"
                      onClick={() =>
                        props.deleteCustomFeature(item.id, "Custom")
                      }
                      size="small"
                      style={{marginRight: "0px"}}
                    >
                      <DeleteOutline fontSize="small" />
                    </IconButton>
                  </div>
                </div>
                <hr className="feature-divider" />
              </>
            ))}
          </div>
        </Scrollbars>
      </div>
    </div>
  )
}

export default CustomFeatureCard
