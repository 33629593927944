import React from "react";
import { Card, Button, Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomButton from "@components/commonComponets/CustomButton";
import InputField from "@components/commonComponets/InputField";
import MInputField from "@components/commonComponets/MInputField";

function LoginForm(props) {
  return (
    <div>
      <Card className="auth-card">
        <Card.Body style={{ padding: "0px" }}>
          <div className="auth-form-main">
            <div className="form-heading">
              <h1 className="form-heading-text">Sign In</h1>
            </div>
            <Form onSubmit={props.submitHandler}>
              {/* <InputField
                value={props.email}
                required={true}
                setChangeHandler={props.setEmail}
                type={"email"}
                placeholder={"Email Address"}
              />
              <InputField
                error={props.passwordError}
                setError={props.setPasswordError}
                value={props.password}
                setChangeHandler={props.setPassword}
                type={"password"}
                placeholder={"Password"}
              /> */}

              <MInputField
                label={"Email"}
                name={"email"}
                value={props.formData.emial}
                handleChange={props.handleFieldChange}
                fullWidth
                margin="normal"
                required={true}
                error={props.errors?.email && true}
                helperText={props.errors?.email}
              />

              <MInputField
                label={"Password"}
                name={"password"}
                type={props.showPassword ? "text" : "password"}
                value={props.formData.password}
                handleChange={props.handleFieldChange}
                fullWidth
                margin="normal"
                required={true}
                error={!!props.errors.message}
                helperText={props.errors.message}
                password={true}
                handleToggle={props.handleTogglePassword}
                showPassword={props.showPassword}
              />

              <Row style={{ textAlign: "right" }}>
                <Col className="forgot-div">
                  <Link
                    onClick={() => props.showForgot()}
                    className="forgot-text"
                  >
                    Forgot Password
                  </Link>
                </Col>
              </Row>

              {/* {props.signInError && <div className='text-danger'>{props.signInError}</div> } */}
              <div className="sign-in-button">
                <CustomButton
                  loading={props.signInLoading}
                  text={"Sign In"}
                  type="submit"
                />
              </div>

              <div className="divider my-3">
                <p>- OR -</p>
              </div>

              {/* <div className='google-auth-container '>
            <Button type='submit' className='google-auth-button'>
                Sign up with Google
            </Button>
        </div> */}

              <Row style={{ textAlign: "center" }}>
                <Col className="redirect-text ">
                  Don't have an account yet?{" "}
                  <Link
                    onClick={() => props.showModal()}
                    className="login-signup"
                  >
                    Sign up here
                  </Link>
                </Col>
              </Row>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default LoginForm;
