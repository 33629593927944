import AppRoutes from "@routes/AppRoutes";
import Navbar from "@components/navBar/Navbar";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import ErrorBoundary from "@components/ErrorBoundary/ErrorBoundary";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";

import { BrowserRouter as Router } from "react-router-dom";

import { store, persistor } from "./store";
// import React, { lazy, Suspense } from "react";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ErrorBoundary>
              <Router>
                <Navbar />

                <AppRoutes />
              </Router>
            </ErrorBoundary>
          </PersistGate>
        </Provider>
      </div>
    </ThemeProvider>
  );
}

export default App;
