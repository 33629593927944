import React from "react"
import InputField from "@components/commonComponets/InputField"
import {Modal, Form, Card} from "react-bootstrap"
import CustomButton from "@components/commonComponets/CustomButton"
import MInputField from "../commonComponets/MInputField"
import {IconButton} from "@material-ui/core"
import {HighlightOff} from "@material-ui/icons"
import CustomCloseButton from "../commonComponets/CustomCloseButton"

function DeliveryForm(props) {
  const handleCloseProjectFrom = () => {
    props.closeForm(false)
  }
  return (
    <Modal
      className="auth-modal"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      //   size="lg"
      show={props.show}
      onHide={handleCloseProjectFrom}
    >
      <Card className="project-from-card">
        <CustomCloseButton cancel={handleCloseProjectFrom} />

        {/* <div className='popup-close-btn-container'>
    <IconButton fontSize={"25"}  onClick={handleCloseProjectFrom} aria-label="close">
  <HighlightOff fontSize={"25"} />
</IconButton>   
    </div> */}
        <Card.Body>
          <Card.Title className="d-flex justify-content-center mt-4 text-muted">
            Project Details
          </Card.Title>
          <form
            className="project-delivery-from"
            onSubmit={props.submitHandler}
          >
            <MInputField
              label={"Project Name"}
              name={"projectName"}
              value={props.formData.projectName}
              handleChange={props.handleFieldChange}
              margin="normal"
              required={true}
              error={!!props.errors.message}
              helperText={props.errors.message}
              fSize={"f-size-14"}
            />

            <MInputField
              label={"Project Description"}
              name={"description"}
              value={props.formData.description}
              handleChange={props.handleFieldChange}
              margin="normal"
              required={true}
              error={props.errors?.description && true}
              helperText={props.errors?.description}
              fSize={"f-size-14"}
              multilies={true}
              rows={3}
            />

            <MInputField
              label={"Email Address"}
              name={"email"}
              value={props.formData.email}
              handleChange={props.handleFieldChange}
              margin="normal"
              required={true}
              error={props.errors?.email && true}
              helperText={props.errors?.email}
              fSize={"f-size-14"}
            />

            <MInputField
              label={"Contact"}
              name={"mobile"}
              value={props.formData.mobile}
              handleChange={props.handleFieldChange}
              margin="normal"
              required={false}
              error={props.errors?.mobile && true}
              helperText={props.errors?.mobile}
              fSize={"f-size-14"}
            />

            <MInputField
              label={"Message"}
              name={"message"}
              value={props.formData.message}
              handleChange={props.handleFieldChange}
              margin="normal"
              required={false}
              error={!!props.errors.message}
              helperText={props.errors.message}
              fSize={"f-size-14"}
              multilies={true}
              rows={3}
            />
            <div className="mt-4 p-0">
              <CustomButton
                // loading={props.submitProjectLoading}
                text={"Submit"}
                type="submit"
              />
            </div>
          </form>
        </Card.Body>
      </Card>
    </Modal>
  )
}

export default DeliveryForm
