import apisauce from "apisauce"

// const baseUrl = process.env.REACT_APP_APILINK;
const baseUrl = "https://builderxapi.sparkrex.com/api"

const create = (baseURL = baseUrl) => {
  const apis = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
    },
    timeout: 200000,
  })

  const login = ({email, password}) => {
    return apis.post("auth/signin", {email: email, password: password})
  }

  const getMyProfile = ({accessToken}) => {
    return apis.get(
      "users/my-profile",
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
  }
  const signUpUser = data => {
    return apis.post("auth/signUp", data)
    // { email, password, loginType,sendVerification,roleType}
    // {email: email, password: password, loginType:loginType,sendVerification:sendVerification,roleType:roleType}
  }

  const requestPasswordReset = ({email}) => {
    return apis.post("auth/request-password-reset", {email})
  }

  const userProfile = ({token}) => {
    return apis.get(
      `/users/my-profile`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }

  const changePassword = ({token, data}) => {
    return apis.post(`/auth/change-password`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const createModule = data => {
    let token = data.token
    delete data["token"]
    return apis.post(`/module`, data, {
      headers: {
        // "Content-Type": 'application/x-www-form-urlencoded;charset=UTF-8',
        // 'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token}`,
        // Accept: "Application/json",
      },
    })
  }

  const getModules = data => {
    return apis.get(
      `/template`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const getModuleDetails = data => {
    return apis.get(
      `/template/${data.id}?subFeatures=true`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const updateModule = data => {
    let token = data.token
    delete data["token"]
    return apis.patch(`/module/${data.module.id}`, data.module, {
      headers: {Authorization: `Bearer ${token}`},
    })
  }

  const deleteModule = data => {
    return apis.delete(
      `/module/${data.moduleId.id}`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const getFeautes = data => {
    return apis.get(
      `/features`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const createFeature = data => {
    let token = data.token
    delete data["token"]
    let item = data.feature

    return apis.post(`/features`, item, {
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const updateFeature = data => {
    let token = data.token
    delete data["token"]
    return apis.patch(`/features/${data.feature.id}`, data.feature, {
      headers: {Authorization: `Bearer ${token}`},
    })
  }

  const deleteCustomFeature = data => {
    return apis.delete(
      `/features/custom/${data.id}`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const getSubFeautes = data => {
    let id = parseInt(data.id)

    return apis.get(
      `/features/subfeatures/${id}`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const createSubFeature = data => {
    let token = data.token
    delete data["token"]
    let item = data.feature

    return apis.post(`/features/subfeatures`, item, {
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const updateSubFeature = data => {
    let token = data.token
    delete data["token"]
    return apis.patch(
      `/features/subfeatures/${data.feature.id}`,
      data.feature,
      {
        headers: {Authorization: `Bearer ${token}`},
      }
    )
  }

  const deleteSubFeature = data => {
    return apis.delete(
      `/features/subfeatures/${data.subFeature.id}`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const allChildFeatureByPId = data => {
    return apis.get(
      `/features/subfeatures/parentfeatureId/${data.id}`,
      {},
      {headers: {Authorization: `Bearer ${data.token}`}}
    )
  }

  const allFeatureByTemplateId = data => {
    return apis.get(`features/template/${data.id}`)
  }

  const createCustomFeature = data => {
    let token = data.token
    delete data["token"]
    let item = data.customFeature

    return apis.post(`/features/custom`, item, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const getCustomFeatures = data => {
    let token = data.token
    return apis.get(
      `/features/template/${data?.id}?Subfeature=false&category=Custom`,

      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }

  const getSubmittedProject = data => {
    let token = data.token
    return apis.get(
      `/user/projects`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }

  const createSubmitPorject = data => {
    let token = data.token
    delete data["token"]
    let item = data.data
    // return apis.post(`/user/submitProject`,
    return apis.post(`/user/new/submitProject`, item, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  const getDomain = data => {
    let token = data.token
    return apis.get(
      `/domain?features=true&subFeatures=true`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }

  // const createquestion = (data) => {
  //   let token = data.token;
  //   delete data["token"];
  //   return apis.post(`/institution/form/questions`, data, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   });
  // };

  return {
    login,
    getMyProfile,
    signUpUser,
    requestPasswordReset,
    userProfile,
    changePassword,
    createModule,
    getModules,
    getFeautes,
    createFeature,
    deleteModule,
    updateModule,
    updateFeature,
    getSubFeautes,
    deleteCustomFeature,
    createSubFeature,
    deleteSubFeature,
    updateSubFeature,
    getModuleDetails,
    allChildFeatureByPId,
    allFeatureByTemplateId,
    createCustomFeature,
    createSubmitPorject,
    getSubmittedProject,
    getDomain,
    getCustomFeatures,
  }
}
export default {
  create,
}
