import React from "react";
import { connect } from "react-redux";
import { useState } from "react";
import { Card } from "react-bootstrap";
import LoginForm from "@components/authComponents/LoginForm";
import img2 from "@assets/images/authImages/img2.png";
import * as _ from "lodash";
import CustomCloseButton from "@components/commonComponets/CustomCloseButton";

function LoginCard(props) {
  let { signInLoading, signInError } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [errors, setErrors] = useState({});

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Remove error when typing in a field
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (formData.email.trim() === "") {
      newErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (formData.password.trim() === "") {
      newErrors.password = "Password is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (validateForm()) {
      let data = {
        email: formData.email.trim(),
        password: formData.password,
        // redirectTo: redirectionParam,
        setShowLogin: props.setShowLogin && props.setShowLogin,
        handleClose: props.handleClose,
        setProjectFromShow:
          props.setProjectFromShow && props.setProjectFromShow,
      };
      props.callLogin(data);
    }
  };

  return (
    <div>
      <Card className="auth-main-card">
        <CustomCloseButton handleClose={props.handleClose} />

        <Card.Body style={{ padding: "0px" }}>
          <div className="auth-card-image">
            {/* <Card.Img  src={img2} /> */}
            <img alt="password show eye" src={img2} />
          </div>
          <div className="auth-container">
            <LoginForm
              formData={formData}
              handleFieldChange={handleFieldChange}
              showPassword={showPassword}
              handleTogglePassword={handleTogglePassword}
              errors={errors}
              signInLoading={signInLoading}
              signInError={signInError}
              passwordError={passwordError}
              setPasswordError={setPasswordError}
              email={email}
              password={password}
              setEmail={setEmail}
              setPassword={setPassword}
              submitHandler={submitHandler}
              showForgot={props.showForgot}
              showModal={props.showModal}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

const mapStateToProps = ({ tracker = {}, user = {} }) => {
  const signInLoading = _.get(user, "signInLoading", false);
  const screen = _.get(tracker, "startupscreen", "");
  const signInError = _.get(user, "signInError", false);

  return { signInLoading, screen, signInError };
};

const mapDispatchToProps = (dispatch) => ({
  callLogin: (data) => dispatch({ type: "userSignInCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginCard);
