import React from 'react'
import {Card, Carousel } from 'react-bootstrap'
import image1 from "@assets/images/homePage/image1.png"
import Ellipse1 from "@assets/images/homePage/Ellipse 2.png"
import { useNavigate } from 'react-router-dom';
import { DOLLAR_SYMBOL } from '../constants/Constans';
import { size } from 'lodash';
import { ClickAwayListener, IconButton, Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Button, Link } from '@mui/material';
import defaultImage from "../assets/images/homePage/default.jpg"


function ServiceCard(props) {
  const navigate = useNavigate()
  // useEffect(() => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };
    
  // }, []);

  let costCalculation = (item)=>{
    let cost = 0
    if(item.features){
      for(let x in item.features ){
        // cost +=x.feature.cost
        cost = cost +item.features[x].feature.cost
      }
      return cost
    }
    else
    return cost

  }
  return (
    <div>
    <Card className='main-template-card'>
    
      <Card.Body className='custom-card-upper'>
     
      <Card.Title className="custom-card-title" style={{fontSize:'16px'}}>{
        
      (props.item.name).substring(0, 70) }
      </Card.Title>

    

      </Card.Body>

      {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
      {/* <Card.Img className="card-main-image" variant="top" src={props.item.templateImages?.length >0? props.item.templateImages[0].original : image1} /> */}
      <div className='imageslider' style={{height:'150px'}}>
     
      
    
          <img  src={props.item.templateImages[0]?.original?props.item.templateImages[0]?.original:defaultImage } />
        
        

      </div>
      <Card.Body className='custom-card-lower' >
      <Card.Subtitle className="text-muted d-flex pt-2" style={{justifyContent:'space-between'}}>
       
          {props.item.features?.length >1 ? 
           <h3 className='custom-subtitle d-inline'>
           <h3 style={{fontSize:"1.20rem",display:'inline'}}>
          {props.item.features?.length-1}+</h3> Features</h3>
          : props.item.features?.length === 1 ?
            <h3 className='custom-subtitle d-inline'>
            <h3 style={{fontSize:"1.20rem",display:'inline'}}>
           {props.item.features?.length}</h3> Feature</h3>
          :null
          }

          

      <h3 style={{fontWeight:"500px", fontSize:"1.1rem",marginRight:'0px' }}>{DOLLAR_SYMBOL} {costCalculation(props.item)}+</h3>
      </Card.Subtitle>
   
        <div className='template-feature'>
 
</div>

      {/* <Card.Text class="custorm-main-text">
          {props.item.description}{' '}
        </Card.Text> */}

        <div className='template-card-bottom mt-3'>
      {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
      {

      }
{/*     
 <Tooltip arrow
           
                disableFocusListener 
                title={<p style={{fontSize:"14px",padding:"5px", fontWeight:'200px', color:"white"}}>{props.item.description}</p>}
              
              >
     
  <Link style={{cursor:'pointer'}}>
    View Details
  </Link>
</Tooltip> */}
        <Button onClick={()=>navigate(`/details/${props.item.id}`)} variant="outlined" color='primary'>Select</Button>
        </div>

        
       
      
      </Card.Body>
      {/* <Card.Footer>
        <small className="text-muted">Last updated 3 mins ago</small>
      </Card.Footer> */}
    </Card>
  </div>
  )
}

export default ServiceCard