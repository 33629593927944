import React from "react"
import {Form} from "react-bootstrap"

function HomeSidebar(props) {
  // console.log(props.domainsList, "domainlist")
  return (
    <div className="sidebar">
      <Form>
        <div className="sidebar-heading-container">
          <p>Select Domain</p>
        </div>
        <hr className="sidebar-component-divider" />
        {props.domainsList?.list?.map((item, index) => (
          <div key={item.id}>
            <Form.Group
              className="sidebar-component"
              controlId="formBasicCheckbox"
            >
              <Form.Label>{item.name}</Form.Label>
              <Form.Check
                onClick={e => props.domainHandler(e, item)}
                type="checkbox"
                id={item.id}
              />
            </Form.Group>
            <hr className="sidebar-component-divider" />
          </div>
        ))}
      </Form>
    </div>
  )
}

export default HomeSidebar
