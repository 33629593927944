import React from "react"
import {connect} from "react-redux"
import {Form, Container, Modal, Accordion, Carousel} from "react-bootstrap"
import image1 from "@assets/images/detailPage/image1.png"
import {Link, useParams, useNavigate} from "react-router-dom"
import AddCustomFeatureCard from "@components/AddCustomFeatureCard"
import CustomFeatureCard from "@components/CustomFeatureCard"
import {useState, useEffect} from "react"
import {
  DeliveryCostFooter,
  DeliveryButton,
} from "@components/costFooter/TemplateCostFooter"
import {
  FeatureDetailsPopUp,
  SuccessPopUp,
  DeleteConfirmPopUp,
} from "@components/popups/PopUpCards"
import Loader from "@components/commonComponets/Loader/Loader"
import * as _ from "lodash"
import {costCalculation} from "./utilities/untilitiesfunctions"
import LoginCard from "@components/authComponents/LoginCard"
import SignUpCard from "@components/authComponents/SignUpCard"
import ForgotCard from "@components/authComponents/ForgotCard"
import Scrollbars from "react-custom-scrollbars-2"
import {DOLLAR_SYMBOL} from "../constants/Constans"
import {Button, Checkbox, FormControlLabel, IconButton} from "@material-ui/core"
import {ArrowBackRounded, InfoOutlined} from "@material-ui/icons"
import {ArrowBackIosNewRounded} from "@mui/icons-material"
import NestedAccordion from "./detailPageComponents/NestedAccordion"
// import { Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel, Typography } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import defaultImage from "../assets/images/homePage/default.jpg"
function TemplateDetail(props) {
  const {
    getModuleDetails,
    templateDetails,
    getCustomFeatures,
    templateDetailsLoading,
    addCustomFeature,
    getFeatures,
    allFeatures,
    customFeatures,
    callDeleteCustomFeature,
    addRequestedData,
    getModules,
    auth,
    customFeatureLoading,
    manageFeatureIds,
    selectedAdminFeatures,
    resetStates,
  } = props
  const navigate = useNavigate()
  const id = useParams()
  useEffect(() => {
    getModules()
    getFeatures()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])
  useEffect(() => {
    if (id) {
      getModuleDetails(id)
      if (auth === true) {
        getCustomFeatures(id)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, auth])

  const allFeatureslist = allFeatures?.list
  const [featurePopupShow, setFeaturePopupShow] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [activeKey, setActiveKey] = useState("0")
  const [customFeatureName, setCustomFeatureName] = useState("")
  const [customFeatureDesc, setCustomFeatureDesc] = useState("")
  const [childFeatureName, setChildFeatureName] = useState("")
  const [childFeatureDesc, setChildFeatureDesc] = useState("")
  const [childFeatureCost, setChildFeatureCost] = useState("")
  const [addOnFeatureCost, setAddOnFeatureCost] = useState(0)
  const [custom, setCustom] = useState(false)
  const [selectedFeatures, setSelectedFeatures] = useState([])

  const [successPopUpShow, setSuccessPopUpShow] = useState(false)
  const [deletePopUpShow, setDeletePopUpShow] = useState(false)
  const [deleteFeatureId, setDeleteFeatureId] = useState()
  const [customFeatureResponseMessage, setCustomFeatureResponseMessage] =
    useState("")
  const [deleteFeatureType, setDeleteFeatureType] = useState()
  const [filteredAdminFeatures, setfilteredAdminFeatures] = useState("")
  const featurePopupHide = () => setFeaturePopupShow(false)
  const successPopupHide = () => setSuccessPopUpShow(false)
  const deletePopupHide = () => setDeletePopUpShow(false)

  const [standardCost, setStandardCost] = useState(
    costCalculation(templateDetails)
  )
  const [fixedCost, setFixedCost] = useState("50000")

  useEffect(() => {
    let cost = costCalculation(templateDetails)
    setStandardCost(cost)
    setfilteredAdminFeatures(
      props.selectedAdminFeatures?.filter(
        selectedAdminFeature => selectedAdminFeature?.templateId == id.id
      )
    )

    // getCustomFeatures()
    getFeatures()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // console.log("customFeature", customFeatures)

  const featurePopupShowHandler = (item, custom) => {
    setFeaturePopupShow(true)
    setChildFeatureCost(item.cost)
    setChildFeatureName(item.name)
    setChildFeatureDesc(item.description)
    setCustom(custom)
  }

  const custumFeatureModalClose = () => {
    setModalShow(false)
    setCustomFeatureDesc("")
    setCustomFeatureName("")
  }
  const customFeatureSubmitHanlder = () => {
    let data = {
      name: customFeatureName,
      description: customFeatureDesc,
      templateId: parseInt(id.id),
      featureCost: "0",
      completionTime: "2",
      setModalShow,
      setCustomFeatureResponseMessage,
      setCustomFeatureDesc,
      setCustomFeatureName,
      setSuccessPopUpShow,
      id: customFeatures.length + 1,
    }
    if (data.name.length > 0 && data.description.length > 0) {
      addCustomFeature(data)
      // getCustomFeatures()
    } else {
      alert("please fill the details")
    }
  }
  const handleCheckboxChange = (event, data) => {
    let isAvailable = filteredAdminFeatures.some(item => item.id === data.id)

    if (event.target.checked) {
      if (!isAvailable) {
        let newData = {
          ...data,
          templateId: id.id,
        }
        manageFeatureIds({method: "Add", data: newData})
        setfilteredAdminFeatures([...filteredAdminFeatures, newData])
        let totalCost = addOnFeatureCost + parseInt(data.cost)

        setAddOnFeatureCost(totalCost)
        // console.log(filteredAdminFeatures, "filteredAdminFeatures")
        // console.log(newData, "handleCheckboxChange")
        // console.log(
        //   addOnFeatureCost,

        //   "handleCheckboxChange"
        // )
      }
    } else {
      let id = data.id
      if (isAvailable) {
        manageFeatureIds({method: "Del", data: id})
        setfilteredAdminFeatures(
          filteredAdminFeatures?.filter(
            filteredAdminFeature => filteredAdminFeature.id != id
          )
        )
      }
      let totalCost = addOnFeatureCost - data.cost
      setAddOnFeatureCost(totalCost)
    }
  }

  const totalFeatureCostCal = items => {
    let filterFeature =
      items?.filter && items.filter(ft => ft?.templateId == id.id)
    let totalCost = 0
    for (let i = 0; i < filterFeature?.length; i++) {
      totalCost += filterFeature[i]?.cost
    }
    return totalCost
  }

  const deleteCustomFeature = (id, type) => {
    setDeletePopUpShow(true)
    setDeleteFeatureId(id)
    setDeleteFeatureType(type)
    // callDeleteCustomFeature(id);
    // window.location.reload();
  }

  const deleteCustomFeatureHandler = () => {
    let id = deleteFeatureId
    if (deleteFeatureType === "Admin") {
      manageFeatureIds({method: "Del", data: id})
      setfilteredAdminFeatures(
        filteredAdminFeatures?.filter(
          filteredAdminFeature => filteredAdminFeature.id != id
        )
      )
    } else if (deleteFeatureType === "Custom") {
      callDeleteCustomFeature(id)
    }
    setDeletePopUpShow(false)
  }

  const deliveryHandler = () => {
    if (filteredAdminFeatures?.length > 0) {
      let selectedFeatureIds = filteredAdminFeatures.map(item => {
        return parseInt(item.id)
      })
      let stdCost = costCalculation(templateDetails)
      const data = {
        standardCost: stdCost,
        fixedCost: fixedCost,
        addOnFeatureCost: totalFeatureCostCal(selectedAdminFeatures),
        templateId: id,
        featureIds: [...selectedFeatureIds],
        name: templateDetails.name,
        description: templateDetails.description,

        customFeatures: customFeatures?.filter(customFeature =>
          customFeature.template.some(
            template => template.template && template.template.id == id.id
          )
        ),
      }
      // customFeatures = customFeatures?.filter(customFeature =>
      //   customFeature.template.some(
      //     template => template.template && template.template.id === id
      //   )
      // )
      // console.log("delievrycustom", data)
      navigate("/delivery", {state: {data}})
      addRequestedData({data})
    } else {
      alert("Please select the Features")
    }
  }

  const [forgotShow, setShowForgot] = useState(false)
  // const handleForgotClose = () => setShowForgot(false);
  const [loginShow, setShowLogin] = useState(false)
  const [signUpShow, setShowSignUp] = useState(false)
  const [loginCard, setLoginCard] = useState(false)
  const [signUpCard, setSignUpCard] = useState(false)
  const [forgetCard, setForgetCard] = useState(false)

  const handleLoginClose = () => setShowLogin(false)

  const handleSignUpShow = () => {
    setShowSignUp(true)
    setLoginCard(false)
    setSignUpCard(true)
  }

  const handleForgotShow = () => {
    setLoginCard(false)
    setForgetCard(true)
    setSignUpCard(false)
  }

  const handleLoginShow = () => {
    setShowLogin(true)
    setShowSignUp(false)
    setShowForgot(false)
    setLoginCard(true)
  }

  const imageRenderingHandler = image => {
    let images = false
    if (image) {
      if (!image.templateImages?.length > 0) {
        return images
      }

      if (image.templateImages?.length < 2) {
        let img = image.templateImages[0].original
        return img
      }

      return image.templateImages[1].original
    }
  }
  const [checkedItems, setCheckedItems] = useState([])

  const handleCheckChange = itemId => {
    const isChecked = checkedItems.includes(itemId)

    if (isChecked) {
      setCheckedItems(checkedItems?.filter(id => id !== itemId))
    } else {
      setCheckedItems([...checkedItems, itemId])
    }
  }
  const filterSelectedFeature = selectedFeature => {
    let filteredItem = selectedFeature.filter(item => item.templateId == id.id)
    return filteredItem
  }

  return (
    <>
      <Modal
        className="auth-modal"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        show={loginShow}
        onHide={handleLoginClose}
      >
        {/* <Modal.Body> */}

        {loginCard ? (
          <LoginCard
            handleClose={handleLoginClose}
            showModal={handleSignUpShow}
            showForgot={handleForgotShow}
          />
        ) : signUpCard ? (
          <SignUpCard
            handleClose={handleLoginClose}
            showModal={handleLoginShow}
          />
        ) : forgetCard ? (
          <ForgotCard
            handleClose={handleLoginClose}
            showModal={handleLoginShow}
          />
        ) : null}

        {/* </Modal.Body> */}
      </Modal>

      {/* -------------Custom feature popup modal start---------------- */}
      <AddCustomFeatureCard
        loading={customFeatureLoading}
        cstFtrName={customFeatureName}
        cstFtrDesc={customFeatureDesc}
        setName={setCustomFeatureName}
        setDesc={setCustomFeatureDesc}
        show={modalShow}
        handleClose={custumFeatureModalClose}
        setShow={setModalShow}
        submitHandler={customFeatureSubmitHanlder}
        onHide={() => setModalShow(false)}
      />

      <Container fluid className="p-0">
        <div className="detail-main-section">
          {templateDetailsLoading && <Loader />}

          {/* ------------------------Sidebar Features Section start--------------------------- */}

          <Modal
            className="feature-details-popup"
            show={featurePopupShow}
            onHide={featurePopupHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <FeatureDetailsPopUp
              title={childFeatureName}
              cost={`${DOLLAR_SYMBOL}${childFeatureCost}`}
              details={childFeatureDesc}
              button_text={"Close"}
              cancel={featurePopupHide}
              custom={custom}
            />
          </Modal>

          <div className="detail-feature-sidebar">
            <div class="template-feature-title">
              <p>Select features</p>
            </div>
            {/* {data.map((item)=> item.id)} */}
            <Scrollbars
              style={{height: "calc(100vh - 135px)"}}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              {/* <Accordion
                className="custom-accordion"
                flush
                activeKey={activeKey}
                onSelect={setActiveKey}
              >
                {allFeatures &&
                  allFeatures.map((item, key) => {
                    if (item.parentFeatureId == null) {
                      return (
                        <Accordion.Item eventKey={key} key={key}>
                          <Accordion.Header>{item.name}</Accordion.Header>
                          {item.childFeatures.map((ft) => (
                            <div className="accordiaon-subbody">
                              <Accordion.Body
                                className="custom-accordion-body"
                                eventKey="0"
                              >
                                <h5>{ft.name}</h5>
                                <div className="accord-actions">
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      featurePopupShowHandler(ft, false)
                                    }
                                    className="feature-detail-button"
                                  >
                                    <InfoOutlined fontSize="small" />
                                  </IconButton>

                                  <Form.Check
                                    className="feature-checkbox"
                                    onChange={(e) =>
                                      handleCheckboxChange(e, ft)
                                    }
                                    type="checkbox"
                                  />
                                </div>
                              </Accordion.Body>
                            </div>
                          ))}
                        </Accordion.Item>
                      );
                    }
                    return null;
                  })}
              </Accordion> */}

              <div>
                {NestedAccordion(
                  allFeatureslist,
                  handleCheckboxChange,
                  featurePopupShowHandler,
                  filteredAdminFeatures
                )}
              </div>
            </Scrollbars>
          </div>

          {/* --------------------Middle section start---------------- */}

          <div className="theme-detail-middle-section">
            <Link to="/" style={{position: "absolute", zIndex: "999"}}>
              <IconButton
                aria-label="delete"
                disabled
                color="primary"
                size="large"
              >
                <ArrowBackRounded fontSize="large" />
              </IconButton>
            </Link>

            <Scrollbars
              style={{height: "calc(100vh - 170px)"}}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              <div className="theme-image-section">
                <Carousel
                  controls={
                    templateDetails?.templateImages?.length > 1 ? true : false
                  }
                  variant="dark"
                  style={{height: "100%", marginTop: "20px"}}
                  indicators={false}
                >
                  {templateDetails?.templateImages?.length > 0 ? (
                    templateDetails?.templateImages?.map(image => {
                      return (
                        <Carousel.Item
                          style={{paddingLeft: "50px", paddingRight: "50px"}}
                        >
                          <img src={image.original} />
                        </Carousel.Item>
                      )
                    })
                  ) : (
                    <Carousel.Item
                      style={{paddingLeft: "50px", paddingRight: "50px"}}
                    >
                      <img src={defaultImage} />
                    </Carousel.Item>
                  )}
                </Carousel>
              </div>

              <div className="theme-containt">
                <div className="template-title">
                  <h1>{templateDetails?.name.substring(0, 70)} </h1>
                  <p className="category" style={{fontSize: "16px"}}>
                    Education
                  </p>
                  <p className="cost-title">Total Cost</p>
                  {/* <p className='cost'>₹ 150000.00</p> */}
                  <p className="cost">
                    {DOLLAR_SYMBOL} {costCalculation(templateDetails)}
                  </p>

                  <p className="detail">{templateDetails?.description}</p>
                </div>
              </div>
            </Scrollbars>
          </div>

          {/* --------------------Middle section end---------------- */}

          {/* ------------------------Detail Custom feature bar start--------------- */}
          <SuccessPopUp
            message={customFeatureResponseMessage}
            show={successPopUpShow}
            status={"succes"}
            button_text={"Close"}
            handleClose={successPopupHide}
          />

          <DeleteConfirmPopUp
            deleteHandler={deleteCustomFeatureHandler}
            loading={customFeatureLoading}
            handleClose={deletePopupHide}
            show={deletePopUpShow}
            status={"alert"}
          />
          <div className="detail-custom-feature-bar">
            <Scrollbars
              style={{height: "calc(100vh - 135px)"}}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
            >
              <CustomFeatureCard
                customFeatures={customFeatures}
                viewDetails={featurePopupShowHandler}
                setShow={setModalShow}
                deleteCustomFeature={deleteCustomFeature}
                filteredAdminFeatures={filteredAdminFeatures}
                templateId={parseInt(id.id)}
              />
            </Scrollbars>
          </div>

          {/* ------------------------Detail Custom feature bar end--------------- */}

          {/* --------------------template cost estimation start------------------------------------- */}
          <div className="cost-estimation-footer-main">
            <div className="cost-estimation-footer-body">
              <DeliveryCostFooter
                addOnFeatureCost={totalFeatureCostCal(selectedAdminFeatures)}
                standardCost={costCalculation(templateDetails)}
                fixedCost={fixedCost}
              />
              <DeliveryButton deliveryHandler={deliveryHandler} />
            </div>
          </div>
          {/* --------------------template cost estimation end------------------------------------- */}
        </div>
      </Container>

      {/* <Container className='detail-main-container'>
       <DetailSideBar />
    <Row className="justify-content-md-center">
    <Col xs lg="7">
    <div className='detail-container'>
      <div className='back-button'>
        <Link to="/"><i class="fa fa-angle-left" aria-hidden="true"></i></Link>
      </div>
       <div className='detail-page-image'>
        <img src={image1} className="img-fluid" />
       </div>
       <div className='detail-containt-section'>
        <div className='template-title'>
          <h1>SOLO - Learning platform</h1>
          <p className='category'>Education</p>
          <p className='cost-title'>Total Cost</p>
          <p className='cost'>₹ 150000.00</p>
          <p className='detail'>Lorem ipsum dolor sit amet consectetur. Fermentum eget nisl cras sit leo tellus. Porttitor est in ornare amet. Eu eu orci fringilla viverra vel viverra et donec. Imperdiet augue venenatis sed sed tristique enim sagittis. </p>


        </div>
       </div>
    </div>
    </Col>
    {/* <Col md="auto">Variable width content</Col>
    <Col xs lg="2">
      3 of 3
    </Col> */}
      {/* </Row>
  </Container> */}

      {/* <DeliveryPlan /> */}
    </>
  )
}

const mapStateToProps = state => {
  const allModules = _.get(state.app, "allModules", [])
  const allFeatures = _.get(state.app, "allFeatures", [])
  const templateDetailsLoading = _.get(state.app, "moduleDetailsLoading", false)
  const templateDetailsError = _.get(state.app, "moduleDetailsError", undefined)
  const templateDetails = _.get(state.app, "moduleDetails", undefined)
  const customFeatureList = _.get(state.app, "customFeatureList", [])
  const requestedData = _.get(state.app, "requestData", [])
  const customFeatures = _.get(state.app, "customFeatures", [])
  const auth = _.get(state.user, "auth", false)
  const signInLoading = _.get(state.user, "signInLoading", false)
  const customFeatureLoading = _.get(state.app, "customFeatureLoading", false)
  const selectedAdminFeatures = _.get(state.app, "selectedFeatureIds", [])

  return {
    allModules,
    allFeatures,
    templateDetails,
    customFeatures,
    templateDetailsError,
    templateDetailsLoading,
    customFeatureList,
    requestedData,
    auth,
    signInLoading,
    customFeatureLoading,
    selectedAdminFeatures,
  }
}

const mapDispatchToProps = dispatch => ({
  addModule: data => dispatch({type: "createModulecalled", payload: data}),
  getModules: data => dispatch({type: "moduleListcalled"}),
  getModuleDetails: data =>
    dispatch({type: "moduleDetailsCalled", payload: data}),
  getFeatures: data => dispatch({type: "featureListcalled"}),
  addFeature: data => dispatch({type: "createFeatureCalled", payload: data}),
  updateFeature: data => dispatch({type: "updateFeatureCalled", payload: data}),
  deleteFeature: data => dispatch({type: "deleteFeatureCalled", payload: data}),
  addCustomFeature: data =>
    dispatch({type: "createCustomFeatureSagaCalled", payload: data}),
  callDeleteCustomFeature: data =>
    dispatch({type: "deleteCustomFeatureCalled", payload: data}),
  addRequestedData: data =>
    dispatch({type: "addRequestDataSagaCalled", payload: data}),

  getCustomFeatures: data =>
    dispatch({type: "getCustomFeatureSagaCalled", payload: data}),
  manageFeatureIds: data =>
    dispatch({type: "manageSelectedFeaturesIdSagaCalled", payload: data}),
  resetStates: data => dispatch({type: "logoutSagaCalled", payload: data}),
})

export default connect(mapStateToProps, mapDispatchToProps)(TemplateDetail)
