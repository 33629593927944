import {call, put} from "redux-saga/effects"
import Api from "@services/ApiCaller"
import {getAccessToken} from "./utilities"
import {
  createFail,
  createSuccess,
  createTrigger,
  editFail,
  editSuccess,
  editTrigger,
  deleteSuccess,
  deleteTrigger,
  deleteFail,
  listTrigger,
  listSuccess,
  listFail,
  moduleDetailsStart,
  moduleDetailsSuccess,
  moduleDetailsFail,
  featureListTrigger,
  featureListSuccess,
  featureListFail,
  // createCustomFeature,
  featuresByTemplateFail,
  featuresByTemplateSuccess,
  // featuresByTemplateTrigger,
  // deleteCustomFeature,
  addRequestData,
  submitProjectStart,
  submitProjectFail,
  submitProjectSuccess,
  createCustomFeatureStart,
  createCustomFeatureFail,
  createCustomFeatureSuccess,
  getSubmitProjectStart,
  getSubmitProjectSuccess,
  getSubmitProjectFail,
  getDomainsStart,
  getDomainsSuccess,
  getDomainsFail,
  getCustomFeatureStart,
  getCustomFeatureFail,
  getCustomFeatureSuccess,
  deleteCustomFeatureFail,
  deleteCustomFeatureSuccess,
  deleteCustomFeatureTrigger,
  addSelectedFeatureIDs,
  delSelectedFeatureIDs,
  // reset,
} from "./appredux"

export function* createModuleSaga(api, {payload = {}}) {
  yield put(createTrigger(true))
  try {
    const token = yield getAccessToken()

    let result
    if (payload && payload.id)
      result = yield call(Api.callServer, {token, ...payload}, true)
    else
      result = yield call(
        Api.callServer,
        api.createModule,
        {token, ...payload},
        true
      )
    yield put(createSuccess(result))
    yield call(Api.callServer, api.getModules, {token}, true)
  } catch (e) {
    yield put(createFail(e))
  }
  yield put(createTrigger(false))
}

export function* moduleListSaga(api, {payload}) {
  yield put(listTrigger())
  try {
    const token = yield getAccessToken()
    const result = yield call(Api.callServer, api.getModules, {token}, true)
    yield put(listSuccess(result))
  } catch (e) {
    alert(e)
    yield put(listFail(e.message))
  }
}

export function* featureListSaga(api, {payload}) {
  yield put(featureListTrigger())
  try {
    const token = yield getAccessToken()
    const result = yield call(Api.callServer, api.getFeautes, {token}, true)
    yield put(featureListSuccess(result))
  } catch (e) {
    alert(e.message)
    yield put(featureListFail(e.message))
  }
}

export function* moduleDetailstSaga(api, {payload}) {
  yield put(moduleDetailsStart())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.getModuleDetails,
      {token, ...payload},
      true
    )
    yield put(moduleDetailsSuccess(result))
  } catch (e) {
    alert(e)
    yield put(moduleDetailsFail(e.message))
  }
}

export function* updateModuleSaga(api, {payload}) {
  yield put(editTrigger())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.updateModule,
      {token, module: payload},
      true
    )
    yield put(editSuccess(result))
  } catch (e) {
    yield put(editFail(e.message))
  }
}

export function* deleteModuleSaga(api, {payload}) {
  yield put(deleteTrigger())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.deleteModule,
      {token, moduleId: payload},
      true
    )
    yield put(deleteSuccess(result))
  } catch (e) {
    yield put(deleteFail(e.message))
  }
}

export function* createCustomFeatureSaga(api, {payload = {}}) {
  yield put(createCustomFeatureStart())
  let setModalShow = payload.setModalShow
  delete payload["setModalShow"]
  try {
    let data = {
      name: payload.name,
      templateId: payload.templateId,
      description: payload.description,
      id: payload.id,
      featureCost: payload.featureCost,
      completionTime: payload.completionTime,
    }
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.createCustomFeature,
      {token, customFeature: data},
      true
    )

    yield put(createCustomFeatureSuccess(result))
    setModalShow(false)
    payload.setSuccessPopUpShow(true)
    payload.setCustomFeatureResponseMessage(
      "Custom Feature Added Successfully!"
    )
    payload.setCustomFeatureDesc("")
    payload.setCustomFeatureName("")
    window.location.reload()
  } catch (e) {
    yield put(createCustomFeatureFail(e.message))
    alert(e.message)
  }
}

// export function* customFeatureListSaga(api, { payload }) {

//     yield put (customFeatureList());
// }

export function* deleteCustomFeatureSaga(api, {payload = {}}) {
  yield put(deleteCustomFeatureTrigger())
  try {
    const token = yield getAccessToken()
    yield call(
      Api.callServer,
      api.deleteCustomFeature,
      {token, id: payload},
      true
    )
    // payload.setDeletePopUpShow(true)

    // payload.setDeleteSuccessMessage(
    //   `The module '${payload.name}' is deleted successfully.`
    // )

    yield put(deleteCustomFeatureSuccess(payload))
    window.location.reload()
  } catch (e) {
    yield put(deleteCustomFeatureFail(e.message))
    alert(e.message)
  }
}

export function* featuresByTemplateSaga(api, {payload}) {
  yield put(featuresByTemplateSuccess())
  try {
    // const token = yield getAccessToken();
    // const result = yield call(
    //   Api.callServer,
    //   api.allFeatureByTemplateId,
    //   { token},
    //   true
    // );
    // yield put(featuresByTemplateSuccess(result));
  } catch (e) {
    alert(e.message)
    yield put(featuresByTemplateFail(e.message))
  }
}

export function* addRequestDataSaga(api, {payload = {}}) {
  // let data = {
  //     featureName:payload.featureName,
  //     featureDesc:payload.featureDesc
  // }

  yield put(addRequestData(payload.data))
}

export function* submitProjectSaga(api, {payload}) {
  yield put(submitProjectStart())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.createSubmitPorject,
      {token, data: payload},
      true
    )
    yield put(submitProjectSuccess(result))
    payload.setForm(false)
    payload.setShow(true)
    payload.resetState()
  } catch (e) {
    yield put(submitProjectFail(e.message))

    alert(e.message)
  }
}

export function* getsubmittedProjectSaga(api, {payload}) {
  yield put(getSubmitProjectStart())
  try {
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.getSubmittedProject,
      {token},
      true
    )
    yield put(getSubmitProjectSuccess(result))
  } catch (e) {
    yield put(getSubmitProjectFail(e.message))

    alert(e.message)
  }
}

export function* getDomainsSaga(api, {payload}) {
  yield put(getDomainsStart())
  try {
    const token = yield getAccessToken()
    const result = yield call(Api.callServer, api.getDomain, {token}, true)
    yield put(getDomainsSuccess(result))
  } catch (e) {
    yield put(getDomainsFail(e.message))

    alert(e.message)
  }
}

export function* getCustomFeatureSaga(api, {payload = {}}) {
  yield put(getCustomFeatureStart())

  try {
    const {id} = payload
    const token = yield getAccessToken()
    const result = yield call(
      Api.callServer,
      api.getCustomFeatures,
      {token, id},

      true
    )
    // let data = result.features.map(item=>{
    //   return item.feature
    // })
    yield put(getCustomFeatureSuccess(result))
  } catch (e) {
    yield put(getCustomFeatureFail(e.message))

    alert("custom feature error", e.message)
  }
}

export function* manageSelectedFeaturesIdSaga(api, {payload}) {
  let data = payload.data
  try {
    if (payload.method === "Add") {
      yield put(addSelectedFeatureIDs(data))
    } else if (payload.method == "Del") {
      yield put(delSelectedFeatureIDs(data))
    }
  } catch (e) {
    // yield put(submitProjectFail(e.message));

    alert(e.message)
  }
}
