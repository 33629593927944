import React from 'react'
import { Modal } from 'react-bootstrap'
import InputField from '@components/commonComponets/InputField'
import { Button, IconButton, TextField } from '@material-ui/core'
import { HighlightOff } from '@material-ui/icons'
import {Spinner} from 'react-bootstrap'
import CustomCloseButton from './commonComponets/CustomCloseButton'


function AddCustomFeatureCard(props) {
  const { cstFtrName, cstFtrDesc,setName ,setDesc,submitHandler} = props

  const handleClose = ()=>{
    props.setShow(false)
  }
  return (
    <div >
        <Modal className='add-custom-feature-main'
        onHide={props.handleClose}
    show={props.show}

// {...props}
// size="lg"
aria-labelledby="contained-modal-title-vcenter"
centered
>

<CustomCloseButton cancel={props.handleClose} />

{/* <div className='close-btn'>
         <IconButton size="large"  onClick={()=>props.setShow(false)} aria-label="close">
  <HighlightOff fontSize='large'/>
</IconButton>      
         
         </div> */}
{/* <Modal.Header closeButton>
</Modal.Header> */}
<Modal.Body className='custom-feature-card'>
         {/* <Card className='custom-feature-card'> */}
      
       
      <div className='custom-feature-card-body'>
        <h3>Custom Feature</h3>
        <p>
        The price and delivery time of custom features may change once we understand exactly what you need.
        </p>

        <InputField value={cstFtrName} setChangeHandler={setName} type='text' placeholder = 'Suggest the name of your feature'/>
        <InputField value={cstFtrDesc} multiline="true" setChangeHandler={setDesc} type='text' placeholder = 'Write a brief description of the features and its requirements'/>
        <div className='create-btn'>

        <Button varient="contained" className='mt-5 custom-feature-add-btn' onClick={submitHandler} color="primary">
        {props.loading?
         <div className='d-flex justify-content-center align-items-center'>
         <Spinner animation="border" style={{width: "1.2rem", height:"1.2rem"} }size="sm" role="status" />
           <span className="mx-3">Loading...</span>
         </div>
         :"Add"
         }
          </Button>
        </div>
      </div>
    {/* </Card> */}
    </Modal.Body>
</Modal>

    </div>
  )
}

export default AddCustomFeatureCard

