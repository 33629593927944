import React from "react";
import { connect } from "react-redux";
import { useEffect,useState } from "react";
import { Badge,Modal } from "react-bootstrap";
import * as _ from "lodash";
import Loader from "@components/commonComponets/Loader/Loader";
import { Link } from "react-router-dom";
import LoginCard from "@components/authComponents/LoginCard";
import SignUpCard from "@components/authComponents/SignUpCard";
import ForgotCard from "@components/authComponents/ForgotCard";


function UserDashboard(props) {
  const { getProjects, allSubmittedProject, getProjectLoading,auth } = props;

  useEffect(() => {
    // window.scrollTo(0, 0);
    if(auth){

      getProjects();
    }else{
      setShowLogin(true)
      setLoginCard(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);



  const [forgotShow, setShowForgot] = useState(false);
  // const handleForgotClose = () => setShowForgot(false);
  const [loginShow, setShowLogin] = useState(false);
  const [signUpShow, setShowSignUp] = useState(false);
  const [loginCard, setLoginCard] = useState(false);
  const [signUpCard, setSignUpCard] = useState(false);
  const [forgetCard, setForgetCard] = useState(false);

  const handleLoginClose = () => setShowLogin(false);

  const handleSignUpShow = () => {
    setShowSignUp(true);
    setLoginCard(false);
    setSignUpCard(true);
  };

  const handleForgotShow = () => {
    setLoginCard(false);
    setForgetCard(true);
    setSignUpCard(false);
  };

  const handleLoginShow = () => {
    setShowLogin(true);
    setShowSignUp(false);
    setShowForgot(false);
    setLoginCard(true);
  };


  return (
    <div className="p-5">
       <Modal
        className="auth-modal"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        show={loginShow}
        onHide={handleLoginClose}
      >
        {/* <Modal.Body> */}

        {loginCard ? (
          <LoginCard
          handleClose = {handleLoginClose}
            showModal={handleSignUpShow}
            showForgot={handleForgotShow}
          />
        ) : signUpCard ? (
          <SignUpCard handleClose = {handleLoginClose} showModal={handleLoginShow} />
        ) : forgetCard ? (
          <ForgotCard handleClose = {handleLoginClose} showModal={handleLoginShow} />
        ) : null}

        {/* </Modal.Body> */}
      </Modal>
      {getProjectLoading && <Loader />}
      {auth? 
      <>
         <div className="p-2">
         <h4>Requested Projects</h4>
       </div>
       {allSubmittedProject.length > 0 ? (
         <table className="table">
           <thead class="table-light">
             <tr>
               <th scope="col">#</th>
               <th scope="col">Project Name</th>
               <th scope="col">Platform</th>
               <th scope="col">Template</th>
 
               <th scope="col">Features</th>
 
               <th scope="col">Total Cost</th>
               <th scope="col">Status</th>
             </tr>
           </thead>
           <tbody>
             {allSubmittedProject &&
               allSubmittedProject?.map((item, index) => (
                 <tr key={index}>
                   <th scope="row">{index + 1}</th>
                   <td>{item.name}</td>
 
                   <td>--</td>
 
                   {/* <td>{item.platform?.map(pt=>
                  <Badge bg="light" text="dark">
                  {pt}
                </Badge>
                 )}</td> */}
                   <td>
                     <Link exact to={`/details/${item?.template?.id}`}>
                       {item?.template?.name}
                     </Link>
                   </td>
 
                   <td>
                     {item.features.length > 0
                       ? item.features.map((ft) => (
                           <Badge bg="ligt" text="dark">
                             {ft.feature?.name}
                           </Badge>
                         ))
                       : "---"}
                   </td>
                   <td>---</td>
                   <td>---</td>
                 </tr>
               ))}
 
             {/* <tr>
           <th scope="row">2</th>
           <td>Jacob</td>
           <td>Thornton</td>
           <td>@fat</td>
         </tr>
         <tr>
           <th scope="row">3</th>
           <td colspan="2">Larry the Bird</td>
           <td>@twitter</td>
         </tr> */}
           </tbody>
         </table>
       ) : (
         <div style={{ margin: "5%", color: "grey", fontSize: "1.3rem" }}>
           <p>You haven't requested any projects</p>
         </div>
       )}
       </>

       :<div> Please Login to see your requested Projects </div>
      
    }
   
    </div>
  );
}

const mapStateToProps = (state) => {
  const allSubmittedProject = _.get(state.app, "submitProject", []);
  const getProjectLoading = _.get(state.app, "getSubmitProjectLoading", []);
  const auth = _.get(state.user, "auth", false);


  return { allSubmittedProject, getProjectLoading,auth };
};

const mapDispatchToProps = (dispatch) => ({
  getProjects: (data) => dispatch({ type: "getsubmittedProjectSagaCalled" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);
