import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AccordionActions,
  Button,
  ButtonGroup,
  IconButton,
} from "@material-ui/core";
import {
  AddBox,
  AddBoxOutlined,
  ArrowBackRounded,
  InfoOutlined,
} from "@material-ui/icons";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import {} from "react-bootstrap";

const NestedAccordion = (
  features,
  handleCheckboxChange,
  featurePopupShowHandler,
  filteredAdminFeatures
) => {
  return features?.map((feature) => {
    const hasChildFeatures =
      feature.childFeatures && feature.childFeatures.length > 0;
    const hasChecked =
      filteredAdminFeatures &&
      filteredAdminFeatures?.some((item) => item.id == feature.id);

    if (hasChildFeatures) {
      return (
        <Accordion
          key={feature.id}
          sx={{
            "&.Mui-expanded": {
              marginTop: "0px",
              marginBottom: "1px",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ marginTop: "0px" }}
            sx={{
              "&.Mui-expanded": {
                marginTop: "0px",
                marginBottom: "0px",
                margin: "0px",
                minHeight: "unset",
              },
            }}
          >
            <Typography
              fontSize={"1rem"}
              lineHeight={"1.3"}
              sx={{ fontWeight: 600 }}
            >
              {feature.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              paddingTop: "0px",
              paddingBottom: "0px",
              marginLeft: "-8px",
              marginRight: "-16px",
            }}
          >
            {NestedAccordion(
              feature.childFeatures,
              handleCheckboxChange,
              featurePopupShowHandler,
              filteredAdminFeatures
            )}
          </AccordionDetails>
        </Accordion>
      );
    } else {
      return (
        <>
          <AccordionActions
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 16px",
              margin: "12px 0px",
            }}
            disableSpacing
          >
            <Typography fontSize={"1rem"} lineHeight={"1.3"}>
              {feature.name}
            </Typography>
            <ButtonGroup>
              <IconButton
                style={{
                  padding: "0px",
                  minWidth: "30px",
                  alignItems: "right",
                }}
                size="small"
                onClick={() => featurePopupShowHandler(feature, false)}
              >
                <InfoOutlined fontSize="" />
              </IconButton>
              <FormControlLabel
                style={{ marginRight: "0px", minWidth: "30px" }}
                control={
                  <Checkbox
                    style={{ padding: "0px", justifyContent: "right" }}
                    checked={hasChecked}
                    onChange={(e) => handleCheckboxChange(e, feature)}
                    icon={<AddBoxOutlined />}
                    checkedIcon={
                      <IndeterminateCheckBoxIcon
                        color="lightRed"
                        sx={{ width: "24px", height: "24px" }}
                      />
                    }
                  />
                }
                label=""
              />
            </ButtonGroup>
          </AccordionActions>
        </>
      );
    }
  });
};

export default NestedAccordion;
