import React from 'react'
import { Routes, Route } from "react-router-dom";
// import { Protected, PrivateRoute } from './PrivateRoute';
import HomePage from '@pages/HomePage';
// import LoginPage from '@pages/authPages/LoginPage';
// import SignUpPage from '../pages/authPages/SignUpPage';
// import ForgotPasswordPage from '../pages/authPages/ForgotPasswordPage';
import DetailPage from '@pages/DetailPage';
import DeliveryPage from '@pages/DeliveryPage';
import TestPage from '@pages/TestPage';
import DashboardPage from '@pages/DashboardPage';
import NotFound from '@components/404/NotFound';
import MyComponent from '../pages/TestComponent';


function AppRoutes() {
  return (
    <div>
    <Routes>
        {/* <Route path="/login" element={<Protected> <LoginPage/></Protected>}></Route> */}
      <Route path="/" element={<HomePage />} />
      <Route path="/details/:id" element={<DetailPage/>} />
      <Route path="/delivery" element={<DeliveryPage/>} />
      <Route path="/test" element={<TestPage/>} />
      <Route path="/my" element={<MyComponent/>} />

      <Route path="/dashboard" element={<DashboardPage/>} />
      <Route path="*" element={<NotFound />} />
        {/* <Route path="/" element={<PrivateRoute><HomePage/></PrivateRoute>}></Route> */}

      </Routes>

    </div>
  )
}

export default AppRoutes