import { all, takeLatest } from "redux-saga/effects";
import API from "@services/Api";

import { userLoginSaga, userSignUpSaga, logoutSaga,resetLoadingSaga } from "./userSaga";
import { moduleListSaga, moduleDetailstSaga,featureListSaga,featuresByTemplateSaga,getDomainsSaga,getCustomFeatureSaga,
  createCustomFeatureSaga,deleteCustomFeatureSaga,addRequestDataSaga,submitProjectSaga, getsubmittedProjectSaga,manageSelectedFeaturesIdSaga
} from "./appSaga";
// import { createFeatureSaga } from "./appsaga";
// import { createModuleSaga,moduleListSaga,deleteModuleSaga,updateModuleSaga } from "./modulesaga";
// import { createFeatureSaga, featureListSaga,updateFeatureSaga,deleteFeatureSaga } from "./featuresaga";
// import { createSubFeatureSaga,subFeatureListSaga,updateSubFeatureSaga,deleteSubFeatureSaga } from "./subFeatureSaga";


const api = API.create();
export default function* root() {
  yield all([
    // takeLatest("getEventCardsSagaCalled", getEventsCardSaga, api),
    // takeLatest("getEventCategorySagaCalled", getEventCategorySaga, api),
    takeLatest("userSignInCalled", userLoginSaga, api),
    takeLatest("userSignupcalled", userSignUpSaga, api),
    takeLatest("logoutSagaCalled", logoutSaga, api),

    takeLatest("resetLoadingSagaCalled", resetLoadingSaga, api),



    // takeLatest("createModulecalled", createModuleSaga, api),
    // takeLatest("deleteModuleCalled", deleteModuleSaga, api),
    takeLatest("moduleListcalled", moduleListSaga, api),
    takeLatest("moduleDetailsCalled", moduleDetailstSaga, api),

    // takeLatest("updateModuleCalled", updateModuleSaga, api),

    takeLatest("createCustomFeatureSagaCalled", createCustomFeatureSaga, api),

    takeLatest("featureListcalled", featureListSaga, api),
    takeLatest("featuresByTemplateSagaCalled", featuresByTemplateSaga, api),

    takeLatest("deleteCustomFeatureCalled",deleteCustomFeatureSaga,api),

    takeLatest("addRequestDataSagaCalled",addRequestDataSaga,api),

    takeLatest("submitProjectSagaCalled",submitProjectSaga,api),

    takeLatest("getsubmittedProjectSagaCalled",getsubmittedProjectSaga,api),
    takeLatest("getDomainsSagaCalled",getDomainsSaga,api),
    takeLatest("getCustomFeatureSagaCalled",getCustomFeatureSaga,api),

    takeLatest("manageSelectedFeaturesIdSagaCalled",manageSelectedFeaturesIdSaga,api),

   
  ]);
}
