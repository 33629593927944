import { Button, IconButton } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import React from 'react'
import {Card,Modal,Spinner } from 'react-bootstrap';
import CustomCloseButton from '../commonComponets/CustomCloseButton';
export function FeatureDetailsPopUp(props) {
  return (
    <Card className='card-wrapper' >
    {/* <div className='popup-close-btn-container'>
    <IconButton fontSize={"25"}  onClick={props.cancel} aria-label="close">
  <HighlightOff fontSize={"25"} />
</IconButton>   
    </div> */}
    <CustomCloseButton cancel={props.cancel} />
  <Card.Body className="popup-card-body" style={{paddingLeft:'25px',paddingRight:'25px'}}>
    <Card.Title className='mt-3'>{props.title}</Card.Title>
    {!props.custom &&
      <Card.Subtitle className="mb-2 text-muted popup-cost-section">
      
      { `cost: ${props.cost}`}
       
       
       </Card.Subtitle>
    }
  
  
    
    <Card.Text>
   {props.details}
    </Card.Text>
    
  </Card.Body>
</Card>
  )
}


export function SuccessPopUp(props) {
  return (
    
    <Modal
    className= {`submit-success-popup ${props.status}`}
    show={props.show}
    onHide={props.handleClose}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Card className="success-popup-card-main">
    <CustomCloseButton cancel={props.handleClose} />

  <Card.Body className=" popup-card-body text-center d-flex flex-column">
    <Card.Title>{props.message}</Card.Title>
    <div className='popup-bg-btn'>
    <Button className='success-popup-btn' onClick={props.handleClose} variant="primary">{props.button_text}</Button>
    </div>
  </Card.Body>
</Card>
</Modal>
  )
}


export function DeleteConfirmPopUp(props) {
  return (
    
    <Modal
    className= {`submit-success-popup ${props.status}`}     //status: alert or success
    show={props.show}
    onHide={props.handleClose}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    
    <Card className="success-popup-card-main">
   
  <Card.Body className=" popup-card-body text-center d-flex flex-column">
    <Card.Title className='mt-3'>Do you want to delete this feature?</Card.Title>
    <div className='delete-popup-bg-btn'>
    <Button disabled= {props.loading && true} onClick={props.deleteHandler} variant="contained" color='danger'>
    {props.loading? 

    (<div className='d-flex justify-content-center align-items-center'>
         <Spinner animation="border" style={{width: "1.2rem", height:"1.2rem"} }size="sm" role="status" />
           <span className="mx-3">Loading...</span>
         </div>)

         : "Yes" }
      </Button>
    <Button className='' onClick={props.handleClose} variant="contained">No</Button>
   

    </div>

    {/* <div className='popup-bg-btn'>
    </div> */}
  </Card.Body>
  
</Card>
</Modal>
  )
}

export function ConfirmPopUp(props) {
  return (
    <div className="confirm-popup-container">
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Body>
        <div className="custom-modal">
          <p className="confirm-text">{props.message}</p>
          <div className="confirm-buttons">
            <Button variant="primary" className="yes-btn" onClick={props.handleLogout}>
              Yes
            </Button>
            <Button variant="danger" className="no-btn" onClick={props.handleClose}>
              No
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </div>

  );
}

